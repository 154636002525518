import API from "../config/API";
import { message } from "antd";
import { Store } from "../redux/store";
import { AwsImageUploadResponseType } from "../shared/types/types";
import { clearReduxData2 } from "./redux.util";
window.Buffer = window.Buffer || require("buffer").Buffer;

const GET = async (url: any, params: any, signal: any = null) => {
  const user: any = Store.getState()?.User?.user;
  try {
    const response = await fetch(API.BASE_URL + url, {
      ...(signal && { signal }),
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    });
    const json = await response.json();
    if (json?.statusCode == 403) {
      clearReduxData2();
    }
    return json;
  } catch (error) {
    throw error;
  }
};

const POST = async (url: any, body: any) => {
  const user: any = Store.getState()?.User?.user;
  try {
    const response = await fetch(API.BASE_URL + url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

const PUT = async (url: any, body: any) => {
  const user: any = Store.getState()?.User?.user;
  try {
    const response = await fetch(API.BASE_URL + url, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

const DELETE = async (url: any) => {
  const user: any = Store.getState()?.User?.user;
  try {
    const response = await fetch(API.BASE_URL + url, {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    });
    return await response.json();
  } catch (error) {
    throw error;
  }
};

const DOCUMENT_UPLOAD = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (file) {
        message.loading({
          type: "loading",
          content: "Action in progress..",
          duration: 1,
        });
        const formDataFiles = new FormData();
        formDataFiles.append("file", file);
        const fileUpload = await fetch(`${API.BASE_URL}${API.FILE_UPLOAD}`, {
          method: "POST",
          body: formDataFiles,
        });
        if (fileUpload.ok) {
          const jsonResponse = await fileUpload.text();
          resolve(jsonResponse);
        } else {
          reject("Failed to upload file");
        }
      } else {
        reject("no file selected");
      }
    } catch (err) {
      reject(err);
    }
  });
};

const COMPRESS_IMAGE = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const response = await fetch(`${API.BASE_URL}${API.IMAGE_COMPRESS}`, {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          const jsonResponse: AwsImageUploadResponseType =
            await response.json();
          const obj = {
            ...jsonResponse,
            url: jsonResponse.Location,
            status: true,
          };
          resolve(obj);
        } else {
          let obj = {
            status: false,
            url: null,
          };
          reject(obj);
        }
      } else {
        reject("no file selected");
      }
    } catch (err) {
      reject(err);
    }
  });
};

const EXCEL_UPLOAD = async (
  file: any,
  category: number,
  subCategory: number
) => {
  return new Promise(async (resolve, reject) => {
    const user: any = Store.getState()?.User?.user;
    try {
      if (file) {
        message.loading({
          type: "loading",
          content: "Action in progress..",
          duration: 1,
        });
        const formDataFiles = new FormData();
        formDataFiles.append("file", file);
        formDataFiles.append("category", String(category));
        formDataFiles.append("subCategory", String(subCategory));
        const fileUpload = await fetch(
          `${API.BASE_URL}${API.PRODUCT_UPLOAD_EXCEL}`,
          {
            method: "POST",
            body: formDataFiles,
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const response = await fileUpload.json();
        resolve(response);
      } else {
        reject("no file selected");
      }
    } catch (err) {
      reject(err);
    }
  });
};
const UPLOAD_IMAGES = async (files: any[]) => {
  const user: any = Store.getState()?.User?.user;
  return new Promise(async (resolve, reject) => {
    try {
      if (files?.length) {
        message.loading({
          type: "loading",
          content: "Action in progress..",
          duration: 1,
        });
        const formDataFiles = new FormData();
        if (Array.isArray(files)) {
          for (const file of files) {
            formDataFiles.append("files", file);
          }
        }
        const fileUpload = await fetch(
          `${API.BASE_URL}${API.PRODUCT_UPLOAD_IMAGES}`,
          {
            method: "POST",
            body: formDataFiles,
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        const response = await fileUpload.json();
        resolve(response);
      } else {
        reject("no file selected");
      }
    } catch (err) {
      reject(err);
    }
  });
};

export {
  GET,
  POST,
  PUT,
  DELETE,
  DOCUMENT_UPLOAD,
  COMPRESS_IMAGE,
  EXCEL_UPLOAD,
  UPLOAD_IMAGES,
};
