import React, { useContext, useEffect, useState } from "react";
import { Button, notification } from "antd";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import {
  BsFillCartCheckFill,
  BsFillChatRightDotsFill,
  BsHandbag,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { BiHeart, BiSolidShareAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import OrderBulkModal from "./modal/bulkOrderModal";
import useToggle from "../../../shared/hook/useToggle";
import { storeCheckout } from "../../../redux/slices/checkoutSlice";
import { SingleProductContext } from "../singleProductContext";
import { FaHeart } from "react-icons/fa6";
import { RiFlag2Fill } from "react-icons/ri";
import { MdOutlineShoppingCart } from "react-icons/md";
const CartCard = () => {
  const data = useContext(SingleProductContext);
  const productInfo = data?.data;
  const addToCart = data?.addtoCart;
  const activeVariant = data?.activeVariant;
  const loadingCart = data?.loadingCart;
  const addWishlist = data?.addWishlist;
  const [quantity, setQuantity] = useState(1);
  const [openModal, toggleModal] = useToggle(false);
  const [existincart, setExistInCart] = useState(false);
  const availableQuantity = activeVariant?.variant?.units ?? productInfo.unit;
  const [notificationApi, contextHolder] = notification.useNotification();
  const [isWobbling, setIsWobbling] = useState(false);
  const [isWobblingCart, setIsWobblingCart] = useState(false);
  const [favourited, setFavourited] = useState(productInfo?.wishlist ?? false);
  const accessToken = useSelector((state: any) => state?.User?.user?.token);
  const buyNow = () => {
    if (productInfo?.status != true) {
      notification.error({ message: `Product is Temporarily not Available` });
      return;
    } else if (productInfo.unit == 0) {
      notification.error({ message: `Product is Out of Stock!!` });
      return;
    } else if (quantity > productInfo.unit) {
      notification.error({ message: `Selected Quantity is Not Available.` });
      return;
    }
    if (activeVariant?.variant?.id) {
      if (activeVariant?.variant?.units == 0) {
        notification.error({ message: `This Variant is out of stock` });
        return;
      } else if (quantity > activeVariant?.variant?.units) {
        notification.error({
          message: `Selected Quantity is Not Available for this Variant`,
        });
        return;
      }
    }

    const obj = {
      name: productInfo?.name,
      buyPrice: activeVariant?.variant?.id
        ? activeVariant?.variant?.price
        : productInfo?.retail_rate,
      productId: productInfo?._id,
      quantity: quantity,
      storeId: productInfo?.store_id,
      totalPrice: Number(
        (activeVariant?.variant?.id
          ? activeVariant?.variant?.price
          : productInfo?.retail_rate) * quantity
      ),
      variantId: activeVariant?.variant?.id ?? null,
      image: activeVariant?.variant?.id
        ? activeVariant?.variant?.image
        : productInfo?.image,
      combination: activeVariant?.variant?.combination,
      storeName: productInfo?.storeDetails?.store_name,
    };
    dispatch(storeCheckout([obj]));
    navigate("/checkout");
  };
  const handleIncrement: any = () => {
    setQuantity((quanti) => {
      if (
        productInfo.unit <= quantity ||
        activeVariant?.variant?.units <= quantity
      ) {
        return quanti;
      }
      return quanti + 1;
    });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getQuantity = (quantity: number) => {
    setQuantity(quantity);
  };
  const Auth = useSelector((state: any) => state.User);
  const User = useSelector((state: any) => state.User.user);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const navigation = useNavigate();
  const handleDecrement: any = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const productPrice =
    activeVariant?.status == true
      ? Number(activeVariant?.variant?.price) * quantity
      : Number(productInfo?.retail_rate) * quantity;

  function containsVariantId(arr: any[], vid: number | null) {
    if (Array.isArray(arr)) {
      if (arr.some((obj) => obj.variantId === null)) {
        return productInfo.cart;
      } else {
        return arr.some((obj) => obj.variantId == vid);
      }
    }
  }
  useEffect(() => {
    setExistInCart(
      containsVariantId(productInfo?.cartDetail, activeVariant?.variant?.id)
    );
  }, [activeVariant?.variant?.id]);
  const shareLink = async () => {
    try {
      if (navigator?.share) {
        await navigator.share({
          title: document?.title,
          url: window?.location?.href,
        });
      } else {
        notification.error({ message: `Failed to share link` });
      }
    } catch (err) {
      notification.error({ message: `Failed to share link` });
    }
  };
  useEffect(() => {
    setFavourited(productInfo?.wishlist);
  }, [productInfo?.wishlist]);

  useEffect(() => {
    setQuantity(1);
  }, [productInfo?._id]);
  return (
    <div className="productDetails-cart-card">
      {contextHolder}
      {productInfo.unit == 0 || activeVariant?.variant?.units == 0 ? (
        <h5 className="text-danger productDetails-text12">
          Currently Out of Stock
        </h5>
      ) : productInfo.unit <= quantity ||
        activeVariant?.variant?.units <= quantity ? (
        <h5 className="text-danger productDetails-text12">{`Only ${availableQuantity} units left`}</h5>
      ) : null}
      <div className="productDetails-flex align-items-center flex-row">
        <div className="productDetails-flex productDetails-v-center productDetails-h-center productDetails-p-6-12">
          <h5 className="productDetails-margin-h-6 price productDetails-txt-bold mb-0">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: Settings.currency ?? "INR",
            }).format(productPrice)}
          </h5>
        </div>
        <div className="productDetails-counter-button">
          <Button
            type="primary"
            shape="circle"
            size="middle"
            icon={<AiOutlineMinus size={"10px"} />}
            onClick={handleDecrement}
            className="productDetails-counter-control"
            disabled={quantity === 0}
          />
          <span className="productDetails-count">{quantity}</span>
          <Button
            type="primary"
            size="middle"
            shape="circle"
            icon={<AiOutlinePlus size={"10px"} />}
            onClick={handleIncrement}
            className="productDetails-counter-control"
          />
        </div>
      </div>

      <div className="productDetails-flex gap-2" style={{ marginTop: 20 }}>
        <Button
          type="primary"
          size="large"
          className="productDetails-cart-btn productDetails-margin-h-6 ms-0"
          onClick={() => {
            if (Auth.auth) {
              buyNow();
            } else {
              navigation("/login");
            }
          }}
        >
          Buy Now
        </Button>
        <Button
          type="default"
          size="large"
          className="productDetails-cart-btn productDetails-margin-h-6 me-0"
          icon={
            existincart ? (
              <BsFillCartCheckFill color="green" />
            ) : (
              <MdOutlineShoppingCart
                size={20}
                color="green"
                className={isWobblingCart ? "wobble" : ""}
              />
            )
          }
          onClick={() => {
            if (Auth.auth) {
              if (typeof addToCart === "function") {
                if (existincart) {
                  navigation("/cart");
                  setIsWobblingCart(false);
                } else {
                  if (productInfo?.status != true) {
                    notification.error({
                      message: `Product is Temporarily not Available`,
                    });
                    return;
                  } else if (productInfo.unit == 0) {
                    notification.error({
                      message: `Product is Out of Stock!!`,
                    });
                    return;
                  } else if (quantity > productInfo.unit) {
                    notification.error({
                      message: `Selected Quantity is Not Available.`,
                    });
                    return;
                  }
                  if (activeVariant?.variant?.id) {
                    if (activeVariant?.variant?.units == 0) {
                      notification.error({
                        message: `This Variant is out of stock`,
                      });
                      return;
                    } else if (quantity > activeVariant?.variant?.units) {
                      notification.error({
                        message: `Selected Quantity is Not Available for this Variant`,
                      });
                      return;
                    }
                  }
                  addToCart(productInfo, quantity);
                  setIsWobblingCart(true);
                }
                // addToCart(productInfo, quantity);
              }
            } else {
              navigation("/login");
            }
          }}
          loading={loadingCart}
        >
          {existincart ? "View Cart" : " Add to Cart"}
        </Button>
      </div>
      <div
        className="productDetails-flex productDetails-space-between align-items-center flex-row"
        style={{ marginTop: 15 }}
      >
        {accessToken ? (
          <Button
            type="text"
            className="productDetails-text-btn1 ps-md-0"
            icon={
              favourited ? (
                <FaHeart
                  color="#ff006a"
                  className={isWobbling ? "wobble" : ""}
                  size={20}
                />
              ) : (
                <FaHeart color="#dbdbdb" size={20} />
              )
            }
            onClick={() => {
              if (typeof addWishlist == "function" && Auth?.auth) {
                addWishlist(productInfo);
                if (productInfo?.wishlist == false) {
                  setFavourited(true);
                  setIsWobbling(true);
                } else {
                  setFavourited(false);
                  setIsWobbling(false);
                }
              }
            }}
          >
            {/* {productInfo?.wishlist === true ? `` : "Like"} */}
          </Button>
        ) : null}

        <Button
          type="text"
          icon={<BiSolidShareAlt />}
          className="productDetails-text-btn1"
          onClick={shareLink}
        >
          Share
        </Button>
        <h6 className="productDetails-secondary-text productDetails-text3 productDetails-margin-h-6 mb-0 d-none d-md-inline-block">
          Any Problem with Product?
        </h6>
        <Button
          type="text"
          icon={<RiFlag2Fill />}
          className="productDetails-text-btn1 pe-md-0"
        >
          Report
        </Button>
        {/* {productInfo?.bulk_order == true ? (
          <Button
            type="primary"
            className="productDetails-cart-btn productDetails-margin-h-6 w-50"
            onClick={() => {
              toggleModal(true);
            }}
          >
            Bulk Order
          </Button>
        ) : null} */}
      </div>
      <OrderBulkModal
        open={openModal}
        modalClose={() => toggleModal(false)}
        setQuantity={getQuantity}
      />
    </div>
  );
};

export default CartCard;
