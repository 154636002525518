import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ProfileDashboard from "../screens/profileDashboard";
import ProfileFavourites from "../screens/profileFavourites";
import ProfileOrders from "../screens/profileOrders";
import ProfileAddress from "../screens/profileAddress";
import ProfileOrderDetailsPage from "../screens/orderDetails";
import FailedPaymentsScreen from "../screens/failedPayments";
import ProfileNotifications from "../screens/profileNotifications";

const ProfileMainArea = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Routes>
      <Route path="/home" element={<ProfileDashboard />} />;
      <Route path="/dashboard" element={<ProfileDashboard />} />;
      <Route path="/favourites" element={<ProfileFavourites />} />;
      <Route path="/orders" element={<ProfileOrders />} />;
      <Route path="/orders/:id" element={<ProfileOrderDetailsPage />} />;
      <Route path="/address" element={<ProfileAddress />} />;
      <Route path="/failed_payments" element={<FailedPaymentsScreen />} />;
      <Route path="/notifications" element={<ProfileNotifications />} />;
    </Routes>
  );
};

export default ProfileMainArea;
