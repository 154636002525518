import React from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";

function FeaturedItem(props: any) {
  const navigation = useNavigate();
  return (
    <div
      className={`${styles["card"]} rounded position-relative `}
      onClick={() => {
        navigation(
          `/products/c/${props?.data?.slug}?id=${window.btoa(
            props?.data?._id
          )}&type=${encodeURIComponent(props?.data?.name)}`
        );
      }}
    >
      <h6 className={`fw-bold position-absolute text-light ${styles["text"]}`}>
        Save more on <br />
        <span className="text-warning fw-medium">{props?.data?.name}</span>
      </h6>
      <img
        className={`${styles["img"]} rounded`}
        src={props?.data?.bannerImg ?? ""}
        alt="Image"
      />
    </div>
  );
}

export default FeaturedItem;
