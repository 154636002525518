import React, { useState } from "react";
import "./styles.scss"; // Import your CSS file for styling
import { Button, Input, Popover, Form, notification } from "antd";
import { AiOutlineMessage, AiOutlineWhatsApp } from "react-icons/ai";
import { PiPhoneCall } from "react-icons/pi";
import { IoMailOutline } from "react-icons/io5";
import { POST } from "../../utils/apiCalls";
import API from "../../config/API";
import { useSelector } from "react-redux";

const FloatingButton = () => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  // console.log(Settings?.contactNumber, Settings?.contactEmail);
  const [notificationApi, contextHolder] = notification.useNotification();
  const handlePopoverVisibleChange = (visible: any) => {
    setPopoverVisible(visible);
  };

  const OnFinsh = async (values: any) => {
    try {
      let url = API.ENQUIRY_CREATE;
      let Obj = {
        email: values?.email,
        message: values?.message,
      };
      let response: any = await POST(url, Obj);
      if (response.status) {
        notificationApi.success({ message: "Enquiry Subitted successfully" });
        setPopoverVisible(false);
      }
    } catch (error) {
      notificationApi.error({ message: "Something went wrong." });
      setPopoverVisible(false);
    }
  };
  const content = (
    <div className="popoverInnerContainer">
      <a
        className="socialMediaContainer"
        href={`https://wa.me/${"0502566434"}`}
        target="_blank"
      >
        <a href={`https://wa.me/${"0502566434"}`} target="_blank">
          <AiOutlineWhatsApp size={32} color="green" />
        </a>
        <span>Sent Us on Whatsapp</span>
      </a>
      <a className="socialMediaContainer" href="#">
        <a href={`tel:${Settings?.contactNumber}`}>
          <PiPhoneCall size={32} color="#3c4b9e" />
        </a>
        <span>custommer Support</span>
      </a>
      <a className="socialMediaContainer" href="#">
        <a href={`mailto:${Settings?.contactEmail}`}>
          <IoMailOutline size={32} color="#000" />
        </a>
        <span>Draft an Email</span>
      </a>
      <Form className="customMsg" onFinish={OnFinsh}>
        <Form.Item
          name="email"
          rules={[
            { type: "email", message: "Please enter a Valid email" },
            { required: true, message: "Please enter email" },
          ]}
        >
          <Input
            size="large"
            placeholder="Enter Email"
            className="mb-3"
            required
          />
        </Form.Item>
        <Form.Item
          name="message"
          rules={[{ required: true, message: "please enter your message" }]}
        >
          <Input.TextArea
            size="large"
            rows={4}
            placeholder="Type your message..."
            className="message-input mb-3"
          />
        </Form.Item>
        <div className="FloatingButton-Btn1">
          <Button
            type="primary"
            className="send-button"
            htmlType="submit"
            size="large"
            style={{ width: "60%" }}
          >
            Send
          </Button>
        </div>
      </Form>
    </div>
  );
  return (
    <div className="floating-button-container">
      {contextHolder}
      <Popover
        title={<span className="floating-txt1">Connect With Us</span>}
        className="popover-main"
        placement="topRight"
        content={content}
        trigger="click"
        open={popoverVisible}
        onOpenChange={handlePopoverVisibleChange}
      >
        <button className="floating-button">
          <AiOutlineMessage size={25} />
        </button>
      </Popover>
    </div>
  );
};

export default FloatingButton;
