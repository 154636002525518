import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { POST } from "../../utils/apiCalls";
import API from "../../config/API";
import { Button, Form, Input, notification } from "antd";
import { Container, Row, Col } from "react-bootstrap";

function ForgottPassword() {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const ForgotPassword = async (val: any) => {
    try {
      setIsLoading(true);
      let url = API.USER_FORGOT_PASSWORD;
      const obj = {
        email: val.email,
      };
      const response: any = await POST(url, obj);
      if (response.status) {
        notificationApi.success({
          message: `Password Reset link has been sent to your mail id. Please check`,
        });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        notificationApi.error({ message: response.message ?? "" });
      }
    } catch (err) {
      notificationApi.error({ message: `Ooops something went wrong...!` });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="Screen-box">
      {contextHolder}
      <br /> <br />
      <Container>
        <Row>
          <Col sm={4} xs={12}></Col>
          <Col sm={4} xs={12}>
            <h2 className="LoginScreen-txt1">Forgot Your Password ?</h2>
            <div className="LoginScreen-txt2">
              Please enter your email address to retrieve your password
            </div>
            <br />
            <div className="LoginScreen-box1">
              <Form onFinish={ForgotPassword}>
                <Form.Item
                  name={"email"}
                  rules={[
                    { required: true, message: "Please enter your email" },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter Email" />
                </Form.Item>

                <Row>
                  <Col sm={6} xs={12}></Col>
                  <Col sm={6} xs={12}>
                    <Button
                      loading={isLoading}
                      block
                      size="large"
                      type="primary"
                      htmlType="submit"
                    >
                      Send Request
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <br />
            {/* <button
              className="LoginScreen-txt4"
              onClick={() => navigation("/login")}
            >
              Already have an account?{" "}
              <span className="LoginScreen-txt5">Login</span>
            </button> */}
          </Col>
          <Col sm={4} xs={12}></Col>
        </Row>
      </Container>
    </div>
  );
}
export default ForgottPassword;
