import { clearCart } from "../redux/slices/cartSlice";
import { clearCheckout } from "../redux/slices/checkoutSlice";
import { clearOrders } from "../redux/slices/failedOrders";
import { clearStore } from "../redux/slices/storeSlice";
import { logout } from "../redux/slices/userSlice";
import { Store } from "../redux/store";

export const clearReduxData = (dispatch: any) => {
  dispatch(logout(null));
  dispatch(clearCart(null));
  dispatch(clearStore(null));
  dispatch(clearOrders(null));
  dispatch(clearCheckout());
};

export const clearReduxData2 = () => {
  Store.dispatch(logout(null));
  Store.dispatch(clearCart(null));
  Store.dispatch(clearStore(null));
  Store.dispatch(clearOrders(null));
  Store.dispatch(clearCheckout());
};
