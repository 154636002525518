import React from "react";
import { Row, Col } from "react-bootstrap";
function Offers(props: any) {
  return (
    <Row className="mx-0">
      {props?.data?.map((item:any)=>{
        return  <Col sm={6} xs={12} className="ps-md-0">
        <div className="Offers-img rounded">
          <img
            className="rounded"
            src={item?.img_desk}
            alt="offer"
          />
        </div>
        <br />
      </Col>
      })}
    </Row>
  );
}

export default Offers;
