import { Alert, Button, Modal, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import MapPicker from "react-google-map-picker";
import API from "../../../config/API";
import { Col, Row } from "react-bootstrap";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { FaLocationCrosshairs } from "react-icons/fa6";

const DefaultZoom = 10;
function LocationPicker(props: any) {
  const [location, setLocation] = useState({
    lat: props?.location?.lat || 25.204849,
    lng: props?.location?.lng || 55.270782,
  });
  const [zoom, setZoom] = useState(DefaultZoom);
  const inputRef: any = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [defaultLocation, setDefaultLocation] = useState({
    lat: props?.location?.lat || 25.204849,
    lng: props?.location?.lng || 55.270782,
  });

  function handleChangeLocation(lat: any, lng: any) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom: any) {
    setZoom(newZoom);
  }

  const generateAddress = async () => {
    props.onChange(location);
    props?.onCancel();
  };

  function handleLocationClick() {
    try {
      setIsLoading(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLocation({
            lat: latitude,
            lng: longitude,
          });
          setDefaultLocation({
            lat: latitude,
            lng: longitude,
          });
        }, handleError);
      } else {
        handleError({ message: "not supported" });
      }
    } catch (err) {
      handleError({ message: "not supported" });
    } finally {
      setIsLoading(false);
    }
  }

  const handleError = (error: any) => {
    setError(`Unable to get your Location. ${error.message}`);
    setIsLoading(false);
  };

  const focusInput = () => {
    setTimeout(() => {
      if (props?.open == true) {
        inputRef?.current?.focus();
      }
    }, 10);
  };

  useEffect(() => {
    focusInput();
  }, [props?.open]);

  return (
    <Modal
      title={<h4>Point Store Location</h4>}
      visible={props?.visible}
      footer={null}
      onCancel={() => props?.onCancel()}
      centered
    >
      <br />
      <MapPicker
        zoom={zoom}
        style={{ height: "400px" }}
        onChangeLocation={handleChangeLocation}
        onChangeZoom={handleChangeZoom}
        apiKey={process.env.REACT_APP_GOOGLE_TOKEN || ""}
        defaultLocation={defaultLocation}
      />
      <br />
      <div className="current-location-box" onClick={handleLocationClick}>
        <div>
          {isLoading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            <FaLocationCrosshairs size={24} />
          )}
        </div>
        <div className="current-location-box2">
          <div className="current-location-txt2">Use My Current Location</div>
        </div>
      </div>
      <br />
      <Row>
        <Col sm={6} xs={12}></Col>
        <Col sm={2} xs={6}>
          <Button block size="large" danger onClick={() => props?.onCancel()}>
            Close
          </Button>
        </Col>
        <Col sm={4} xs={6}>
          <Button
            size="large"
            block
            type="primary"
            onClick={() => generateAddress()}
          >
            Done
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default LocationPicker;
