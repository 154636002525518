import { Button, Card, Modal, notification } from "antd";
import Meta from "antd/es/card/Meta";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import API from "../../../config/API";
import { PUT } from "../../../utils/apiCalls";
import useToggle from "../../../shared/hook/useToggle";

function PaymentCardAuth(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();
  const [openModal, setOpenModal] = useToggle(false);
  const [loading,setLoading]=useState(false);
  const completePayment = async () => {
    const url = API.COMPLETE_PAYMENT + props?.data?.id;
    try {
      setLoading(true)
      const response: any = await PUT(url, {});
      if (response?.status) {
        Notifications["success"]({
          message: "Payment status updated successfully.",
        });
        if (typeof props?.getOrderDetails == "function") {
          props?.getOrderDetails();
          setOpenModal(false);
        }
      } else {
        Notifications["error"]({
          message: response?.message,
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong.",
      });
    }finally{
      setLoading(false)
    }
  };
  const Settings = useSelector((state: any) => state.Settings.Settings);
  return (
    <Card bordered={false}>
      {contextHolder}
      <Meta
        // title={`PaymentType: ${props?.data?.orderPayment?.paymentType ?? ""}`}
        description={
          <div className="text-dark">
            <div>
              PaymentType:{" "}
              <span className="fw-bold">
                {props?.data?.orderPayment?.paymentType ?? ""}
              </span>
            </div>
            <div>
              Total ProductPrice:{Number(props?.data?.total)?.toFixed(2)}{" "}
              {Settings.currency}
            </div>
            <div>
              Tax: {Number(props?.data?.tax)?.toFixed(2)} {Settings.currency}
            </div>
            <div>
              Discount: {Number(props?.data?.discount)?.toFixed(2)}{" "}
              {Settings.currency}
            </div>
            <div>
              Delivery Charge: {Number(props?.data?.deliveryCharge)?.toFixed(2)}{" "}
              {Settings.currency}
            </div>
            <div>
              Grand Total: {Number(props?.data?.grandTotal)?.toFixed(2)}
              {Settings.currency}
            </div>
            <hr />
            <div className="fw-bold">
              Payment status:{" "}
              <span
                className={
                  props?.data?.orderPayment?.status == "pending"
                    ? "text-secondary"
                    : props?.data?.orderPayment?.status == "failed"
                    ? "text-danger"
                    : props?.data?.orderPayment?.status == "success"
                    ? "text-success"
                    : "text-info"
                }
              >
                {props?.data?.orderPayment?.status}
              </span>
            </div>
            <div className="fw-bold">
              {props?.data?.orderPayment?.ref ? "Amount Paid" : "Total Price"}:{" "}
              {Number(props?.data?.orderPayment?.amount)?.toFixed(2)}
              {Settings.currency}
            </div>
            {props?.data?.orderPayment?.ref ? (
              <div className=" fw-bold">
                Payment Reference: {props?.data?.orderPayment?.ref}
              </div>
            ) : null}

            <div>
              orderDate:{" "}
              {moment(props?.data?.orderPayment?.createdAt).format(
                "DD/MM/YYYY"
              )}
            </div>
            <div>Order ID: {props?.data?.order_id}</div>
            {props?.data?.orderPayment?.status != "success" &&
            props?.data?.paymentType == "pay-on-credit" ? (
              <Button className="mt-3" onClick={setOpenModal.bind(true)}>
                Complete Payment
              </Button>
            ) : null}
          </div>
        }
      />
      <Modal
        title="Complete The Payment"
        open={openModal}
        centered
        onOk={() => completePayment()}
        onCancel={setOpenModal.bind(false)}
        okText="Update"
        confirmLoading={loading}
      >
        <p>
          The payment will be successful after this. Click on Update if the payment is
          successfully Received for this Order.
        </p>
      </Modal>
    </Card>
  );
}

export default PaymentCardAuth;
