import { PiAddressBook } from "react-icons/pi";
import { Collapse, Drawer } from "antd";
import { useSelector } from "react-redux";
import DrawerProfile from "./drawerProfile";
import { LiaStarSolid } from "react-icons/lia";
import { MdFormatListBulleted } from "react-icons/md";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { BsBookmarkHeart, BsShopWindow } from "react-icons/bs";
import { AiOutlineUnorderedList } from "react-icons/ai";
import LanguageSelector from "./components/languageSelector";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";

function SideBar(props: any) {
  const Category = useSelector((state: any) => state.Category.categries);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const User = useSelector((state: any) => state.User.user);
  const Auth = useSelector((state: any) => state.User);
  const { Panel } = Collapse;
  const navigation = useNavigate();
  const iconSize = 22;

  const OpenLink = (link: any) => {
    if (Auth.auth) {
      navigation(link);
    } else {
      navigation("/login");
    }
    props?.close();
  };

  const StoreLink = () => {
    if (Auth?.auth) {
      switch (User?.data?.type) {
        case "user":
          navigation("/seller");
          break;
        case "seller":
          navigation(`/auth/check_store?id=${Auth?.user?.data?.store_id}`);
          break;
        case "admin":
          navigation(`/auth/check_store?id=${Auth?.user?.data?.store_id}`);
          break;
      }
    } else {
      navigation("/seller");
    }
    props?.close();
  };

  const CategoryLink = (
    categoryId: string,
    categoryName: string,
    slug: string
  ) => {
    const encodedCategoryId = window.btoa(categoryId);
    const path = `/products/c/${slug}?id=${encodedCategoryId}&type=${encodeURIComponent(
      categoryName
    )}`;

    navigation(path);
    props?.close();
  };

  return (
    <Drawer
      title={<DrawerProfile />}
      placement={"left"}
      width={280}
      onClose={props?.close}
      open={props?.open}
      key={"left"}
      closeIcon={false}
    >
      <div style={{ marginTop: -20 }}>
        {Auth.auth ? (
          <>
            <div
              className="Sidebar-itemBox"
              onClick={() => {
                OpenLink("/profile/home");
              }}
            >
              <CgProfile size={iconSize} color="#757575" />
              <span>Profile</span>
            </div>
            <div
              className="Sidebar-itemBox"
              onClick={() => {
                OpenLink("/profile/orders");
              }}
            >
              <AiOutlineUnorderedList size={iconSize} color="#757575" />
              <span>My Orders</span>
            </div>
            <div
              className="Sidebar-itemBox"
              onClick={() => {
                OpenLink("/profile/favourites");
              }}
            >
              <BsBookmarkHeart size={iconSize} color="#757575" />
              <span>Wishlist</span>
            </div>
            <div
              className="Sidebar-itemBox"
              onClick={() => {
                OpenLink("/profile/address");
              }}
            >
              <PiAddressBook size={iconSize} color="#757575" />
              <span>My Address</span>
            </div>
            <hr />
          </>
        ) : null}

        {Auth.auth ? (
          (User?.data?.type === "seller" && Settings.type === "multi") ||
          User?.data?.type === "admin" ? (
            <div className="Sidebar-itemBox" onClick={() => StoreLink()}>
              <BsShopWindow size={iconSize} color="#757575" />
              <span>Manage Store</span>
            </div>
          ) : (
            User?.data?.type === "user" && (
              <div className="Sidebar-itemBox" onClick={() => StoreLink()}>
                <BsShopWindow size={iconSize} color="#757575" />
                <span>Become a Seller</span>
              </div>
            )
          )
        ) : (
          <div
            className="Sidebar-itemBox"
            role="button"
            tabIndex={0}
            onClick={() => StoreLink()}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                StoreLink();
              }
            }}
          >
            <BsShopWindow size={iconSize} color="#757575" />
            <span>{"Become a Seller"}</span>
          </div>
        )}
        <hr />
        <div className="Sidebar-txt1">Categories</div>
        <Collapse
          className="Sidebar-collapse"
          bordered={false}
          expandIconPosition="end"
        >
          {Category?.map((item: any, idx: number) => {
            return (
              <Panel header={item?.name} key={idx}>
                {item?.sub_categories?.map((subCat: any, i: number) => {
                  return (
                    <div
                      key={i}
                      className="Sidebar-txt2"
                      onClick={() =>
                        CategoryLink(subCat._id, subCat.name, subCat?.slug)
                      }
                    >
                      {subCat?.name}
                    </div>
                  );
                })}
              </Panel>
            );
          })}
        </Collapse>
        <div>
          <LanguageSelector />
        </div>
        <hr />

        <div className="Sidebar-itemBox" onClick={() => OpenLink("/about")}>
          <MdFormatListBulleted size={iconSize} color="#757575" />
          <span>About us</span>
        </div>
        <div className="Sidebar-itemBox">
          <LiaStarSolid size={iconSize} color="#757575" />
          <span>Rate us</span>
        </div>
        <div
          className="Sidebar-itemBox"
          onClick={() => {
            OpenLink("/contact");
          }}
        >
          <IoIosHelpCircleOutline size={iconSize} color="#757575" />
          <span>Need Help ?</span>
        </div>
      </div>
    </Drawer>
  );
}
export default SideBar;
