import React, { useEffect } from "react";
import { Col, Container } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import API from "../../config/API";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../components/breadCrumbs";

const Terms = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { t } = useTranslation();

  return (
    <div className="Screen-box">
      <Container>
        <br />
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          {/* <div className="heading_bredcume">
            <span>Home</span> <IoIosArrowForward /> About <IoIosArrowForward />{" "}
            Terms Of Service
          </div> */}

          <BreadCrumbs title="Home" title2="About" title3="Terms Of Service" />
          <div className="footer_heading">{t("Terms_Service.Terms_Of_Service.Terms_Of_Service1")}</div>
          <br />
          <strong> {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt")} {API.NAME}!</strong>
          <br />
          <br />
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxta")}
          </p>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxtb")}
          </p>
          <h6>1.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_1")}</h6>
          <p>
            {API.NAME} {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_1a")}
            {API.NAME} {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_1a1")}
          </p>
          <br />
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_1b")}
          </p>
          <br />
          <p>
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_1c")}
          </p>
          <h6>2.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2")}</h6>
          <br />
          <h6>2.1.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_1")}</h6>
          <br />
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_1a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_1b")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_1c")}&nbsp;
            <a href={`mailto://${API.CONTACT_MAIL}`}>{API.CONTACT_MAIL}</a> By
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_1d")}
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_1e")}
          </p>
          <h6>2.2.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_2")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_2a")}{API.NAME}. {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_2b")}
          </p>
          <h6>2.3.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_3")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_3a")}
          </p>
          <h6>2.4.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_4")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_4a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_4b")}
          </p>
          <p>
            {" "}
            *{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_4c")}<br />*{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_4d")}
          </p>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_4e")} {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_4f")}
          </p>
          <h6>2.5.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_5")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_5a")}
          </p>
          <h6>2.6.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_6")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_6a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_6b")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_6c")}{''}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_6d")}{API.NAME}
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_2_6e")}{" "}
          </p>
          <h6>3.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3")}</h6>
          <h6>3.1.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_1")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_1a")}{API.NAME}{" "}
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_1b")}
          </p>
          <h6>3.2.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_2")}</h6>
          <p>
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_2a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_2b")}{" "}
            {API.NAME}’s{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_2c")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_2d")}
          </p>
          <h6>3.3.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_3")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_3a")}
          </p>
          <h6>3.4.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_4")}{API.NAME}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_4a")}{API.NAME}’s{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_4b")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_4c")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_3_4d")}
          </p>
          <h6>4.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4")}</h6>
          <p>
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4a")}{API.NAME}{" "}
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4b")}
          </p>
          <h6>4.1.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_1")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_1a")}{" "}
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_1b")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_1c")}
          </p>
          <h6>4.2.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_2")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_2a")}{API.NAME}.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_2b")}
          </p>
          <h6>4.3.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_3")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_3a")}
          </p>
          <p>
            {" "}
            *{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_3b")}
            <br />
            *{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_3c")}
            <br />*{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_3d")}
            <br />
            *{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_3e")}
            <br />
            *{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_3f")}
            <br />*{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_3g")}
            <br />
            <br />
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_3h")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_3h1")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_4_3h2")}
          </p>
          <h6>5.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_5")}</h6>
          <h6>5.1.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_5_1")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_5_1a")}
          </p>
          <h6>6.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_6")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_6a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_6b")}
          </p>
          <h6>7.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_7")}</h6>
          <h6>7.1.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_7_1")}</h6>
          <p>
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_7_1a")}
          </p>
          <h6>8.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8")}</h6>
          <h6>8.1.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_1")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_1a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_1b")}
          </p>
          <h6>8.2 {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2")}</h6>
          <p>
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2a")}
          </p>
          <ul>
            <li>
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_1")}
            </li>
            <li>
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_2")}
            </li>
            <li>
              {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_3")}
            </li>
            <li>
              {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_4")}
            </li>
            <li>
              {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_5")}
            </li>
            <li>
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_6")}{API.NAME}{''}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_6a")}
            </li>
            <li>
              {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_7")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_7a")}{" "}
              {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_7b")}
            </li>
            <li>
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_8")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_8a")}
            </li>
            <li>
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_9")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_9a")}
            </li>
            <li>
              {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_10")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_10a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_10b")}
            </li>
            <li>
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_11")}{" "}
              {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_2b_11a")}
            </li>
          </ul>
          <h6>8.3.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_3")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_8_3a")}
          </p>
          <h6>9.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_9")}</h6>
          <p>
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_9a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_9b")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_9c")}{" "}
            <a href={`mailto://${API.CONTACT_MAIL}`}>{API.CONTACT_MAIL}</a> ,
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_9d")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_9e")}
          </p>
          <h6>10.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_10")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_10a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_10b")}{" "}
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_10c")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_10d")}{API.NAME},{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_10e")}{" "}
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_10f")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_10g")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_10h")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_10i")}{" "}
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_10j")}
          </p>
          <h6>11.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11")}</h6>
          <h6>11.1.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_1")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_1a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_1b")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_1c")}
          </p>
          <h6>11.2.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_2")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_2a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_2b")}{" "}
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_2c")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_2d")}
          </p>
          <h6>11.3.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3a")}{API.NAME}{" "}
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3b")}{" "}
            <a
              href={`https://${API.WEBSITE}/privacy-policy`}
              target="_blank"
              rel="noreferrer"
            >
              {API.WEBSITE}/privacy-policy
            </a>{" "}
            ,{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3c")}{API.NAME},{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3d")}{" "}
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3e")}{" "}
            {API.NAME}'s{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3f")}{" "}
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3g")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3h")}{API.NAME}{" "}
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3i")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3j")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3k")}{" "}
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3l")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3m")}{API.NAME}{" "}
            {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_3n")}
          </p>
          <h6>11.4.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4a")}{API.NAME}{" "}
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4b")}{API.NAME}{" "}
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4c")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4d")}{API.NAME}{" "}
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4e")}{API.NAME}'s{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4f")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4g")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4h")}{API.NAME},{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4i")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4j")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_4k")}
          </p>
          <h6>11.5.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_5")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_5a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_5b")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_5c")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_5d")}{API.NAME}'s{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_5e")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_5f")}
          </p>
          <h6>11.6.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_6")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_6a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_6b")}{" "}
            {API.NAME}.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_6c")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_6d")}
          </p>
          <h6>11.7.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_7")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_7a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_7b")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_7c")}
          </p>
          <h6>11.8.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_8")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_8a")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_8b")}{" "}
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_8c")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_8d")}
          </p>
          <h6>
            11.9. <strong>{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_9")}</strong>
          </h6>
          <p>
            <strong>{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_9a")}</strong>.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_9b")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_9c")}{API.NAME}
          </p>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_9d")}
          </p>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_9e")}
          </p>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_9f")}{" "}
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_9f1")}
          </p>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_9g")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_9h")}{API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_9i")}
          </p>
          <h6>11.10.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_10")}</h6>
          <p>
          {t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_10a")}
          </p>
          <h6>11.11.{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_11")}</h6>
          <p>
            {API.NAME}{t("Terms_Service.Terms_Of_Service.Welcome_to_nxt_11_11a")}
            <a href={`mailto://${API.CONTACT_MAIL}`}>
              {" "}
              &nbsp;{API.CONTACT_MAIL}
            </a>
          </p>
        </Col>
        <br />
      </Container>
    </div>
  );
};
export default Terms;
