import React, { useEffect, useState, useRef } from "react";
import { Button, Tabs, TabsProps } from "antd";
import PageHeader from "../components/PageHeader";
import SellerDetailsPage from "./tabs/sellerDetails";
import OrderDetails from "./tabs/orderDetails";
import SettlementDetails from "./tabs/settlements";
import { useParams } from "react-router-dom";
import Orders, { OrderPageAdminRefType } from "./tabs/orders";
import Search from "antd/es/input/Search";
import SettlementHistory, { ResetType } from "./tabs/settlement_history";
import SettlementModal from "./tabs/settlementModal";

function SellerDetails() {
  const params = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [key, setKey] = useState("informations");
  const [search, setSearch] = useState("");
  const ref = useRef<OrderPageAdminRefType>(null);
  const [searching, setSearching] = useState(false);
  const resetRef = useRef<ResetType>(null);
  const resetRef2 = useRef<ResetType>(null);
  const items: TabsProps["items"] = [
    {
      key: "informations",
      label: "Seller Info",
      children: <SellerDetailsPage storeId={params?.id} />,
    },
    {
      key: "settlements-summary",
      label: "Settlement Summary",
      children: (
        <SettlementDetails
          ref={resetRef2}
          storeId={params?.id}
          closeModal={() => setOpenModal(false)}
          openModal={openModal}
        />
      ),
    },
    {
      key: "settlement-history",
      label: "Settlement History",
      children: (
        <SettlementHistory
          ref={resetRef}
          storeId={params?.id}
          closeModal={() => setOpenModal(false)}
          openModal={openModal}
        />
      ),
    },
    {
      key: "orders",
      label: "Order Summary",
      children: <OrderDetails storeId={params?.id} />,
    },
    {
      key: "orderlist",
      label: "Order History",
      children: (
        <Orders
          storeId={params?.id}
          search={search}
          ref={ref}
          setSearching={setSearching}
        />
      ),
    },
  ];

  const onChange = (key: string) => {
    setKey(key);
  };
  const onSearch = async (value: string) => {
    if (value) {
      setSearch(value);
    }
  };
  return (
    <>
      <PageHeader title="Seller Info">
        {" "}
        <Button
          type="primary"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Settle Payments
        </Button>
        {key == "orderlist" ? (
          <Search
            placeholder="Search Order ID"
            allowClear
            enterButton="Search"
            size="middle"
            style={{ width: "300px" }}
            onSearch={(value: string) => {
              ref?.current?.search(value);
            }}
            type="number"
            loading={searching}
          />
        ) : null}
      </PageHeader>
      <Tabs defaultActiveKey={key} items={items} onChange={onChange} />
      <SettlementModal
        openModal={openModal}
        storeId={params?.id}
        closeModal={() => setOpenModal(false)}
        refresh={() => {
          resetRef?.current?.reset();
          resetRef2?.current?.reset();
        }}
      />
    </>
  );
}

export default SellerDetails;
