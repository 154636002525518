import { notification } from "antd";
import React, {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import API from "../../../config/API";
import { GET, POST, PUT } from "../../../utils/apiCalls";
import AdminLoading from "../../components/AdminLoading";
import { Col, Row } from "react-bootstrap";

type props = {
  storeId: number | string | undefined;
  closeModal: Function;
  openModal: boolean;
};
type ResetType = {
  reset: () => void;
};
function SettlementSummary({ storeId }: props, ref: Ref<ResetType>) {
  const [settlements, setSettlements] = useState<any>({});
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(true);
  const pageSize = 12;
  const totalsData = [
    {
      title: "Total Amount to Settle",
      value: Number(settlements?.totalOrderPrice)?.toFixed(2),
    },
    {
      title: "Total Amount Settled",
      value: Number(settlements?.totalSettledPrice)?.toFixed(2),
    },
    {
      title: "Balance to Settle",
      value: Number(settlements?.amountToSettle)?.toFixed(2) ?? 0,
    },
    {
      title: "Settlement Pending",
      value: Number(settlements?.settlementPending)?.toFixed(2) ?? 0,
    },
  ];
  const getSettlements2 = async (current: number = 1) => {
    const url =
      API.SETTLEMENT_DETAILS_SUMMARY +
      storeId +
      `?order=ASC&page=${current}&take=${pageSize}`;
    try {
      if (storeId) {
        const response: any = await GET(url, null);
        if (response?.status) {
          setSettlements(response?.data);
        } else {
          Notifications["error"]({
            message: response?.message ?? "",
            description: "",
          });
        }
      }
    } catch (err) {
      Notifications["error"]({
        message: "Somethig went wrong..",
        description: "",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSettlements2();
  }, []);

  useImperativeHandle(ref, () => ({
    reset: getSettlements2,
  }));
  return (
    <div>
      {contextHolder}
      {loading ? (
        <AdminLoading />
      ) : (
        <>
          <Row className="TotalscardContainer g-3">
            {totalsData.map((data, index) => (
              <Col md={3} key={index}>
                <div className="Totalscard">
                  <div className="title">
                    <p className="title-text">{data.title}</p>
                  </div>
                  <div className="data">
                    <p>{data.value}</p>
                    <div className="range">
                      {/* <div className="fill"></div> */}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
}
export default forwardRef(SettlementSummary);
