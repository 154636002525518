import { Badge, Button, notification } from "antd";
import React, { useState } from "react";
import { MdFavoriteBorder } from "react-icons/md";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { RiListUnordered } from "react-icons/ri";
import { PiAddressBook } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import { logoutChannel } from "../../../logout";
import API from "../../../config/API";
import { GET } from "../../../utils/apiCalls";
import { RiNotification2Line } from "react-icons/ri";

const ProfileMenu = (props: any) => {
  const navigation = useNavigate();
  const Auth = useSelector((state: any) => state.User);
  const User = useSelector((state: any) => state.User.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [Notifications, contextHolder] = notification.useNotification();
  const iconSize = 17;
  const OpenLink = (link: any) => {
    if (Auth.auth) {
      navigation(link);
    } else {
      navigation("/login");
    }
  };

  const logotFunction = () => {
    if (Auth.auth) {
      signout();
    } else {
      navigation("/login");
      props.close();
    }
  };

  const signout = async () => {
    const url = API.USER_LOGOUT;
    try {
      setLoading(true);
      const response: any = await GET(url, null);
      if (response?.status) {
        Notifications["success"]({
          message: `You have been Logged Out.`,
          description: "",
        });
        logoutChannel.postMessage("Logout");
        props.close();
      } else {
        alert("Unable to logout.. please try again..");
      }
    } catch (err) {
      alert("Unable to logout.. please try again..");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="profileMenu-Box1">
      {contextHolder}
      <div className="profileMenu-Icon">
        {User?.data?.image ? (
          <img
            src={User?.data?.image}
            className="Header-ProfileImag"
            alt="profile"
          />
        ) : (
          <HiOutlineUserCircle size={45} color="#d9d9d9" />
        )}
        <div style={{ marginTop: 5, marginBottom: 5, fontWeight: "900" }}>
          {User?.data?.first_name || "Guest User"}
        </div>
      </div>

      {Auth.auth && (
        <>
          <div
            className="profileMenu-Box2"
            onClick={() => {
              OpenLink("/profile/home");
              props.close();
            }}
          >
            <div>
              <FiUser size={iconSize} className="profileMenu-Img1" />
            </div>
            <div className="profileMenu-Txt1">Profile</div>
          </div>
          <div
            className="profileMenu-Box2"
            onClick={() => {
              OpenLink("/profile/orders");
              props.close();
            }}
          >
            <div>
              <RiListUnordered size={iconSize} className="profileMenu-Img1" />
            </div>
            <div className="profileMenu-Txt1">Orders</div>
          </div>
          <div
            className="profileMenu-Box2"
            onClick={() => {
              OpenLink("/profile/favourites");
              props.close();
            }}
          >
            <div>
              <MdFavoriteBorder size={iconSize} className="profileMenu-Img1" />
            </div>
            <Badge
              count={User?.data?.wishlist ?? ""}
              size="small"
              color={API.COLOR}
            >
              <div className="profileMenu-Txt1">Favorites</div>
            </Badge>
          </div>
          <div
            className="profileMenu-Box2"
            onClick={() => {
              OpenLink("/profile/address");
              props.close();
            }}
          >
            <div>
              <PiAddressBook size={iconSize} className="profileMenu-Img1" />
            </div>
            <div className="profileMenu-Txt1">Address</div>
          </div>
          <div
            className="profileMenu-Box2"
            onClick={() => {
              OpenLink("/profile/notifications");
              props.close();
            }}
          >
            <div>
              <RiNotification2Line
                size={iconSize}
                className="profileMenu-Img1"
              />
            </div>
            <Badge
              count={User?.data?.notifications ?? ""}
              size="small"
              color={API.COLOR}
            >
              <div className="profileMenu-Txt1">Notifications</div>
            </Badge>
          </div>
        </>
      )}
      <div style={{ margin: 5 }} />
      <Button
        size="large"
        type="primary"
        block
        onClick={() => logotFunction()}
        loading={loading}
      >
        {Auth.auth ? "Logout" : "Login"}
      </Button>
      {Auth.auth ? null : (
        <div style={{ marginTop: "10px" }}>
          New Customer? &nbsp;&nbsp;
          <Link
            to="signup/user"
            onClick={() => {
              if (typeof props.close == "function") {
                props.close();
              }
            }}
          >
            <a href="">Sign Up</a>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;
