import React, { useEffect, useState } from "react";
import SettlementDataTable from "../components/datatable";
import API from "../../../config/API";
import { GET } from "../../../utils/apiCalls";
import { notification } from "antd";

function SettlementScreen2() {
  const [settlements, setSettlements] = useState<any[]>([]);
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const pageSize = 12;
  const [page, setPage] = useState(1);
  const getSettlements = async (current: number = 1) => {
    const url =
      API.SETTLEMENT_DETAILS_HISTORY + `?order=ASC&page=${current}&take=${pageSize}`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setSettlements(response?.data);
        setMeta(response?.meta);
      } else {
        Notifications["error"]({
          message: response?.message,
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong.",
        description: "",
      });
    } finally {
      setLoading(false);
    }
  };
  const changePage = async (page: number) => {
    await getSettlements(page);
    setPage(page);
  };
  useEffect(() => {
    getSettlements();
  }, []);
  return (
    <>
      {contextHolder}
      <SettlementDataTable
        data={settlements}
        page={page}
        meta={meta}
        pageSize={pageSize}
        changePage={changePage}
        loading={loading}
      />
    </>
  );
}

export default SettlementScreen2;
