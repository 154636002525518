import { AutoComplete, Form, Input, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import useDebounce from "../../../shared/hook/useDebounce";
import { GET } from "../../../utils/apiCalls";
import API from "../../../config/API";
import { storeLocation } from "../../../redux/slices/locationSlice";
import { useDispatch } from "react-redux";
import { CiSearch } from "react-icons/ci";
import { LoadingOutlined } from "@ant-design/icons";

function AutoCompleteLocationInput(props: any) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<any[]>([]);
  const [searchParam, setSearchParam] = useState("");
  const searchLocation = useDebounce(searchParam, 300);
  const [notificationApi, contextHolder2] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const getAutoComplete = (value: string) => {
    setSearchParam(value);
  };
  const getLocation = async (option: { value: string; key: string }) => {
    try {
      setIsLoading(true);
      const response: any = await GET(
        API.GOOGLE_PLACEPICKER + `?place_id=${option?.key}`,
        null
      );
      if (response?.status) {
        dispatch(storeLocation(response?.data));
        props?.onCancel();
        setIsLoading(false);
        notificationApi.success({
          message: `Your location has been set to ${response?.data?.full_address}`,
        });
      } else {
        notificationApi.error({
          message: `Failed to get your location ,reason:${response.message}`,
        });
        setIsLoading(false);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const locationAutoComplete = async (value: string) => {
    try {
      const response: any = await GET(
        API.AUTO_COMPLETE + `?query=${value}`,
        null
      );
      if (response?.status) {
        setOptions(response?.data);
      } else {
        notificationApi.error({ message: `Failed to load places.` });
      }
    } catch (error) {
      notificationApi.error({ message: `Failed to get Location details` });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchLocation) {
      locationAutoComplete(searchLocation);
    }
  }, [searchLocation]);

  return (
    <div>
      {contextHolder2}
      <Form.Item
        name="location"
        rules={[{ required: true, message: "Please Enter Location" }]}
      >
        <AutoComplete
          options={options}
          value={searchParam}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={(value, option) => getLocation(option)}
          onChange={getAutoComplete}
        >
          <Input
            size="large"
            placeholder="Search for area or street name"
            prefix={
              isLoading ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : (
                <CiSearch />
              )
            }
          />
        </AutoComplete>
      </Form.Item>
    </div>
  );
}

export default AutoCompleteLocationInput;
