import "./styles.scss";
import DeskTop from "./desktopHeader";
import Mobile from "./mobileHeader";
import CateogreyList from "./cateogreyList";
import React, { useEffect } from "react";
import {
  useGetSettings,
  // useLoadCartUser,
  useLoadCategories,
  useTokenExpiration,
} from "./fetchingDatas";
function Header() {
  useLoadCategories();
  useGetSettings();
  useTokenExpiration();
  // useLoadCartUser();
  return (
    <div className="Header">
      <div className="Header-desktop">
        <DeskTop />
        <CateogreyList />
      </div>
      <div className="Header-mobile">
        <Mobile />
      </div>
    </div>
  );
}
export default Header;
