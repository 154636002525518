import React, { useEffect, useState } from "react";
import "../cartScreen/styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

import AddressBox from "./components/addressBox";
import PaymentBox from "./components/paymentBox";
import SummaryCard from "./components/summaryCard";

import API from "../../config/API";
import { GET, POST } from "../../utils/apiCalls";
import { storeFinal } from "../../redux/slices/checkoutSlice";
import NotDeliverableModal from "./components/notDeliverable";
import useToggle from "../../shared/hook/useToggle";

function Checkout() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const User = useSelector((state: any) => state.User.user);
  const Checkout = useSelector((state: any) => state?.Checkout);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [payment_method, setPayment_method] = useState<any>("Pay Online");
  const [isLoading, setIsLoading] = useState<any>(false);
  const [deliveryToken, setDeliveryToken] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");

  const [total, setTotal] = useState<any>(0);
  const [delivery_charge, setDelivery_charge] = useState<any>(0);
  const [discount, setDiscount] = useState(0);
  const [grand_total, setGrand_total] = useState<any>(0);
  const [openModal, toggleModal] = useToggle(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    CalculateDeliveryCharge();
  }, [Checkout?.address?.id]);

  const CalculateDeliveryCharge = async () => {
    try {
      var totals = 0;
      if (Array.isArray(Checkout?.Checkout) == true) {
        Checkout?.Checkout?.forEach((item: any) => {
          totals += Number(item?.totalPrice);
        });
      }
      setTotal(Number(totals).toFixed(2));
      setGrand_total(totals);
      let obj = {
        cart: Checkout?.Checkout,
        address: Checkout?.address,
        total: totals,
      };
      if (Checkout?.address?.id) {
        const response: any = await POST(API.CALCULATE_DELIVERY_CHARGE, obj);
        if (response?.status) {
          setDeliveryToken(response?.token);
          let delivery = Number(response?.details?.totalCharge);
          let gTotal =
            Number(totals) +
            Number(delivery) -
            Number(response?.data?.discount);
          setDelivery_charge(delivery);
          setGrand_total(gTotal);
          setDiscount(response?.data?.discount);
        } else {
          toggleModal(true);
          setErrorMessage(response?.message ?? "");
          setDeliveryToken("");
          setDelivery_charge(0);
          setGrand_total(totals);
          setDiscount(0);
        }
      }
    } catch (err) {
      setDelivery_charge(0);
      setDiscount(0);
      setDeliveryToken("");
      console.log("err", err);
    }
  };

  const PlaceOrder = async () => {
    if (deliveryToken) {
      // if delivery details available..only
      try {
        const obj = {
          payment: payment_method,
          cart: Checkout?.Checkout,
          address: Checkout?.address,
          charges: {
            token: deliveryToken,
          },
        };
        dispatch(storeFinal(obj));
        if (payment_method === "Pay Online") {
          GetPaymentToken();
        } else {
          navigation("/checkoutsuccess/1", { replace: true });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      if (Checkout?.address?.id) {
        toggleModal(true);
        // notificationApi.error({
        //   message: `Delivery to the Selected address is not available. Please choose another one.`,
        // });
        return;
      }
      notificationApi.error({
        message: `Please Choose a Delivery Address to place an Order`,
      });
    }
  };

  const GetPaymentToken = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(API.PAYMENT_GATEWAY_GETTOKEN, null);
      if (response.access_token) {
        let obj = {
          currencyCode: Settings?.currency,
          value: Number(Number(grand_total) * 100).toFixed(2),
          emailAddress: User?.data?.email,
          firstName: User?.data?.first_name,
          lastName: User?.data?.last_name,
          token: response.access_token,
        };
        var order: any = await POST(API.PAYMENT_GATEWAY_ORDER, obj);
        if (order?._links?.payment?.href) {
          window.location.replace(order?._links?.payment?.href);
        } else {
          notificationApi.error({
            message: `Payment Faild. Please Try Again`,
            description: `Something went wrong. please try again`,
          });
        }
      } else {
        notificationApi.error({
          message: `Payment Faild. Please Try Again`,
          description: `Something went wrong. please try again`,
        });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      notificationApi.error({
        message: `Payment Faild. Please Try Again`,
        description: `Something went wrong. please try again`,
      });
    }
  };

  return (
    <div className="Screen-box">
      {contextHolder}
      <br />
      <Container fluid style={{ minHeight: "80vh" }}>
        <Row>
          <Col sm={7}>
            <AddressBox />
            <PaymentBox
              method={payment_method}
              onChange={(value: any) => setPayment_method(value)}
            />
            <br />
          </Col>
          <Col sm={5}>
            <div className="Cart-box2">
              <SummaryCard
                Cart={Checkout}
                total={total}
                delivery_charge={delivery_charge}
                grand_total={grand_total}
                placeOrder={() => PlaceOrder()}
                loading={isLoading}
                discount={discount}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <NotDeliverableModal
        open={openModal}
        close={() => toggleModal(false)}
        message={errorMessage}
      />
    </div>
  );
}
export default Checkout;
