import { Avatar, List, Pagination, notification } from "antd";
import React, { useEffect, useState } from "react";
import API from "../../../config/API";
import { GET } from "../../../utils/apiCalls";
import Loading from "../../../components/loading";
import NoData from "../../../components/noData";
import { AiOutlineNotification } from "react-icons/ai";
import { navigateNotification } from "../../../utils/notifications.util";
import { useNavigate } from "react-router-dom";

function ProfileNotifications() {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [meta, setMeta] = useState<any>({});
  const [notifications, setNotifications] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const pageSize = 12;
  const [page, setPage] = useState(1);
  const getNotifications = async () => {
    const url = API.USER_NOTIFICATIONS + `?page=${page}&take=${pageSize}`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setNotifications(response?.data);
        setMeta(response?.meta);
      } else {
        notificationApi.error({ message: response?.message });
      }
    } catch (err) {
      notificationApi.error({ message: `something went wrong!` });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getNotifications();
  }, [page]);
  const onclick = (item: any) =>
    navigation(navigateNotification(item?.typeId, item?.type));
  return (
    <>
      {contextHolder}
      <div className="fs-5 fw-medium">{`My Notifications (${
        meta?.itemCount ?? 0
      })`}</div>
      <hr />
      {loading ? (
        <Loading />
      ) : notifications.length ? (
        <>
          {" "}
          <List
            itemLayout="horizontal"
            dataSource={notifications}
            locale={{ emptyText: "You have No Notifactions at the moment.." }}
            renderItem={(item: any, index) => (
              <List.Item
                className="order-list-item"
                onClick={() => onclick(item)}
              >
                <List.Item.Meta
                  avatar={
                    <div className="h-100">
                      <img
                        className=""
                        src={item?.image}
                        style={{
                          width: "50px",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </div>
                  }
                  title={<h6>{item?.title}</h6>}
                  description={item?.message}
                />
              </List.Item>
            )}
          />
          <div className="d-flex justify-content-center mt-3">
            <Pagination
              current={page}
              pageSize={pageSize}
              total={meta?.itemCount || 0}
              defaultCurrent={1}
              responsive={true}
              defaultPageSize={pageSize}
              disabled={false}
              hideOnSinglePage={true}
              onChange={(current: any, size: any) => {
                setPage(current);
              }}
            />
          </div>
        </>
      ) : (
        <NoData
          icon={<AiOutlineNotification size={70} color="#e6e6e6" />}
          header="No Notifications."
          text1={`You have no notifications yet!`}
        />
      )}
    </>
  );
}

export default ProfileNotifications;
