import React, { useEffect, useState } from "react";
import API from "../../../config/API";
import "../styles.scss";
import { GET, PUT } from "../../../utils/apiCalls";
import { Button, Popconfirm, notification } from "antd";
import { CorporateRegisterType } from "../../../shared/types/types";
import NoData from "../../../components/noData";
import { Col, Row, Card } from "react-bootstrap";
import AdminLoading from "../../components/AdminLoading";
import moment from "moment";
type sellerDetailsType = CorporateRegisterType & {
  createdAt: Date;
  status: string;
};
type props = {
  storeId: number | string | undefined;
};
function SellerDetailsPage({ storeId }: props) {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [sellerDetails, setSellerDetails] = useState<sellerDetailsType>();
  const [Notifications, contextHolder] = notification.useNotification();
  const [settlementDetails, setSettlementDetails] = useState<any>({});
  const seller = [
    {
      heading: "Business Details",
      details: {
        "Business Location":
          sellerDetails?.lat ?? "" + sellerDetails?.long ?? "",
        "Business Address": sellerDetails?.business_address,
        "Business Type": sellerDetails?.business_address,
        "Trn Number": sellerDetails?.trn_number,
        "Trade Liscence Number": sellerDetails?.trade_lisc_no,
        "Trn Documnet": sellerDetails?.trn_upload,
      },
    },
    {
      heading: "Seller Details",
      details: {
        "Seller Name": sellerDetails?.seller_name,
        Country: sellerDetails?.seller_country,
        "Birth Country": sellerDetails?.birth_country,
        Dob: moment(sellerDetails?.dob).format("MM/DD/YYYY"),
        "ID Type": sellerDetails?.id_type,
        "ID Proof": sellerDetails?.id_proof,
        "ID Issue Country": sellerDetails?.id_issue_country,
        "ID Expiry Date": moment(sellerDetails?.id_expiry_date).format(
          "MM/DD/YYYY"
        ),
      },
    },
    {
      heading: "Store Details",
      details: {
        "Store Name": sellerDetails?.store_name,
        UPSCS: sellerDetails?.upscs,
        Manufacture: sellerDetails?.manufacture,
        Status: sellerDetails?.status,
      },
    },
    {
      heading: "Date",
      details: {
        "Submited Date": moment(sellerDetails?.createdAt).format("MM/DD/YYYY"),
      },
    },
  ];
  const totalsData = [
    {
      title: "Total Orders",
      value: settlementDetails?.totalOrderCount ?? 0,
      details: "Count of Total Delivered Orders",
    },
    {
      title: "Order Total",
      value: settlementDetails?.totalOrderAmount ?? 0,
      details: "Sum of Total delivered orders",
    },
    {
      title: "Total Products",
      value: settlementDetails?.productsCount ?? 0,
      details: "Total Active Products",
    },
    {
      title: "Total Settled",
      value: settlementDetails?.settledAmount ?? 0,
      details: "Total Amount Settled",
    },
  ];
  const handleDownload = (imageUrl: any) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = "downloaded_file.jpg";
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  };
  const getSellerDetails = async () => {
    setLoading(true);
    const url = API.CORPORATE_STORE_GETSTOREINFO_ADMIN + storeId;
    if (storeId) {
      try {
        const response: any = await GET(url, null);
        if (response?.status) {
          setSellerDetails(response?.data);
        }
      } catch (err: any) {
        Notifications["error"]({
          message: "Something went wrong",
          description: err.message,
        });
      } finally {
        setLoading(false);
      }
    }
  };
  const getSettlementDetails = async () => {
    const url = API.SETTLEMENT_DETAILS_STORE + storeId;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setSettlementDetails(response?.data);
      }
    } catch (err) {
    } finally {
      setLoading2(false);
    }
  };
  const deactivateSeller = async () => {
    const url = API.STORE_DEACTIVATE + storeId;
    try {
      const response: any = await PUT(url, {});
      if (response.status) {
        Notifications["success"]({
          message: response?.message ?? "",
        });
        getSellerDetails();
      } else {
        Notifications["error"]({
          message: response?.message ?? "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong.",
      });
    }
  };
  useEffect(() => {
    getSellerDetails();
    getSettlementDetails();
  }, []);
  return (
    <>
      {contextHolder}
      {loading ? (
        <AdminLoading />
      ) : sellerDetails?.name ? (
        <div className="detail-container">
          <Row className="mt-2">
            <Col md="6">
              <div className="seller-info">
                <div className="seller-avatar">
                  <img src={sellerDetails.logo_upload} alt="Admin Avatar" />
                </div>
                <div className="seller-details">
                  <p className="seller-name">{sellerDetails?.name}</p>
                  <p className="seller-email">{sellerDetails?.email}</p>
                  <p className="seller-email">
                    {sellerDetails.code} {sellerDetails.phone}
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex justify-content-end">
                <Popconfirm
                  placement="bottomRight"
                  title={`Are you sure to ${
                    sellerDetails.status == "approved"
                      ? "Deactivate"
                      : "Activate"
                  } this Seller on Nextme?`}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => deactivateSeller()}
                >
                  <Button
                    type="primary"
                    style={{
                      backgroundColor:
                        sellerDetails?.status == "approved"
                          ? "orange"
                          : "green",
                    }}
                  >
                    {sellerDetails?.status == "approved"
                      ? "Deactivate Seller"
                      : sellerDetails?.status == "cancelled"
                      ? "Activate Seller"
                      : ""}
                  </Button>
                </Popconfirm>
              </div>
            </Col>
          </Row>
          <div>
            {loading2 ? null : (
              <Row className="TotalscardContainer g-3">
                {totalsData.map((data, index) => (
                  <Col md={3} key={index}>
                    <div className="Totalscard">
                      <div className="title">
                        <p className="title-text">{data.title}</p>
                      </div>
                      <div className="data">
                        <p>{data.value}</p>
                        <div className="settlement-card-info">
                          {data?.details ?? ""}
                        </div>
                        {/* <div className="range">
                        <div className="fill"></div>
                      </div> */}
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            )}
          </div>
          <Row className="g-3 mt-3">
            {seller.map((item, index) => (
              <Col md={6} key={index}>
                <Card className="datail-card">
                  <Card.Body>
                    <Card.Title className="card-heading">
                      {item.heading}
                    </Card.Title>
                    <hr />
                    <ul className="card-items">
                      {Object.entries(item.details).map(([key, value]) => (
                        <li key={key}>
                          <div className="key">{key}:</div>
                          {key === "ID Proof" || key === "Trn Documnet" ? (
                            <button
                              className="download-btn"
                              onClick={() => handleDownload(value)}
                            >
                              Download
                            </button>
                          ) : (
                            <div className="value">{value ?? ""}</div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <NoData />
      )}
    </>
  );
}

export default SellerDetailsPage;
