import { Alert, Avatar, Button, Card, Popconfirm } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import API from "../../../config/API";

function DeleteProduct(props: any) {
  return (
    <div style={{ minHeight: "64vh" }}>
      <div>
        <Alert
          description={
            <div>
              If you delete a product it will remove the product from the entire
              application Including user's Cart, wishlist and this will also
              clear the product's reviews, images, it's variants, user's
              history.
              <br />
              <b className="text-danger">This operation cannot be reversed!!</b>
              <br />
            </div>
          }
          type="warning"
          closable
        />
      </div>
      <Card
        style={{ marginTop: "20px" }}
        actions={[
          <div>
            <Popconfirm
              placement="bottomRight"
              title={"Are You sure to Delete this product?"}
              okText="Yes"
              cancelText="No"
              onConfirm={async () => {
                await props?.deleteProduct(props?.data?._id);
              }}
            >
              <Button type="primary" danger className="px-4">
                Delete Product
              </Button>
            </Popconfirm>
          </div>,
        ]}
      >
        <Meta
          avatar={<Avatar src={props?.data?.image} shape="square" size={50} />}
          title={props?.data?.name}
          description={`${props?.data?.description}`}
        />
      </Card>
    </div>
  );
}

export default DeleteProduct;
