import { useDispatch, useSelector } from "react-redux";
import API from "../../config/API";
import { GET, POST } from "../../utils/apiCalls";
import { storeCategory } from "../../redux/slices/categorySlice";
import { storeCart } from "../../redux/slices/cartSlice";
import { useEffect } from "react";
import { storeSettings } from "../../redux/slices/settingsSlice";
import { login, update } from "../../redux/slices/userSlice";
import { jwtDecode } from "jwt-decode";
import { logoutChannel } from "../../logout";
import { message } from "antd";
const delay = 10000; //before this time the token will refreshed.

export const useLoadCategories = () => {
  //loading all categories and subcategoreis on refresh/visiting site(header)
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response: any = await GET(API.CATEGORY, null);
        if (response?.status) {
          dispatch(storeCategory(response?.data));
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [dispatch]);
};

export const useGetSettings = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchSettings = async () => {
      const url = API.SETTINGS;
      try {
        const settings: any = await GET(url, null);
        if (settings.status) {
          dispatch(storeSettings(settings.data));
        }
      } catch (err) {}
    };

    fetchSettings();
  }, [dispatch]);
};

// export const useLoadCartUser = () => {
//   const dispatch = useDispatch();
//   const accessToken = useSelector((state: any) => state?.User?.user?.token);

//   useEffect(() => {
//     let currentDate = new Date();
//     if (!accessToken) return;
//     try {
//       const decoded: any = jwtDecode(accessToken);
//       if (decoded.exp && decoded.exp * 1000 + delay > currentDate.getTime()) {
//         //these apis will be get called only if the accesstoken is not expired.
//         fetchUser();
//         fetchCartItems();
//       }
//     } catch (err) {}
//   }, [dispatch]);
// };

export const useTokenExpiration = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state: any) => state?.User?.user?.token);
  const refresh = useSelector((state: any) => state?.User?.user?.refreshToken);
  useEffect(() => {
    if (!accessToken) return;
    try {
      const decoded: any = jwtDecode(accessToken);
      let currentDate = new Date();
      if (decoded.exp && decoded.exp * 1000 < currentDate.getTime() - delay) {
        if (!refresh) {
          handleTokenExpiration();
        } else {
          createRefreshToken();
        }
      } else {
        fetchUser();
        fetchCartItems();
        const timer = setTimeout(() => {
          createRefreshToken();
        }, decoded.exp * 1000 - Date.now() - delay);

        return () => clearTimeout(timer);
      }
    } catch (err) {
      console.log(err);
    }
  }, [accessToken]);
  const createRefreshToken = async () => {
    const url = API.USER_REFRESH_TOKEN;
    try {
      const response: any = await POST(url, { refreshToken: refresh });
      if (response?.status) {
        dispatch(login(response));
        message.loading({
          type: "loading",
          content: "Updating User Info..",
          duration: 1,
        });
      } else {
        handleTokenExpiration();
      }
    } catch (err) {
      handleTokenExpiration();
    } finally {
    }
  };

  const fetchUser = async () => {
    const url = API.USER_REFRESH;
    try {
      const user: any = await GET(url, null);
      if (user?.status && user?.data?.status == true) {
        dispatch(update(user?.data));
      } else {
        console.log("lout");
        return;
      }
    } catch (err) {}
  };

  const fetchCartItems = async () => {
    try {
      const url = API.CART_GET_ALL;
      const cartItems: any = await GET(url, null);
      if (cartItems.status) {
        dispatch(storeCart(cartItems.data));
      }
    } catch (err) {}
  };

  const handleTokenExpiration = () => {
    message.warning({
      type: "loading",
      content: "Your Session Has been Expired Please Login Again..",
      duration: 2,
      onClose: () => {
        logoutChannel.postMessage("Logout");
      },
    });
  };
};
