import {
  Badge,
  Button,
  Pagination,
  Popconfirm,
  Table,
  notification,
} from "antd";
import { AiTwotoneDelete } from "react-icons/ai";
import { BiSolidEditAlt } from "react-icons/bi";
import { useState } from "react";
import API from "../../config/API";
import { DELETE } from "../../utils/apiCalls";
import "./styles.scss";
import React from "react";
import { FaList } from "react-icons/fa6";
import { ExecuteFunction } from "../../shared/helpers/executeFunction";

function DataTable(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();
  const itemDeleteHandler = async (item: any) => {
    const url = API.SUB_CATEGORY_EDIT + item?._id;
    try {
      const response: any = await DELETE(url);
      if (response.status) {
        Notifications["success"]({
          message: "Success",
          description: response.message,
        });
        props?.getSubCategory();
      } else {
        Notifications["error"]({
          message: "Failed to Delete",
          description: response.message ?? "",
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Failed to Delete",
        description: err.message,
      });
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "position",
      key: "position",
      render: (position: any, _record: any) => (
        <Badge count={position} showZero size="small" color={API.COLOR}>
          <FaList
            cursor="pointer"
            size={20}
            color={"grey"}
            onClick={() => {
              ExecuteFunction(props?.openPosition);
              ExecuteFunction(props?.setPosition, _record);
            }}
          />
        </Badge>
      ),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (item: any) => (
        <img
          alt={"image"}
          src={item}
          className="subcategory-table-card-image"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (item: any) => item.name,
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: (_text: any, _record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <BiSolidEditAlt
            cursor="pointer"
            size={25}
            color={API.COLOR}
            onClick={() => {
              props?.openModal();
              props?.changeType();
              props?.receiveData(_record);
            }}
          />

          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => itemDeleteHandler(_record)}
          >
            <Button type="link">
              <AiTwotoneDelete cursor="pointer" size={25} color={API.COLOR} />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <Table
        size="small"
        dataSource={props.data}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {},
          };
        }}
        pagination={false}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={props?.page || 1}
          pageSize={props?.pageSize || 10}
          total={props?.meta?.itemCount || 10}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            props?.changePage(current, size);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
