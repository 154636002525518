import React, { useEffect } from "react";
import { Col, Container } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import API from "../../config/API";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../components/breadCrumbs";

const Cancellation = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <Container>
        <br />
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          {/* <div className="heading_bredcume">
            Home <IoIosArrowForward /> About <IoIosArrowForward />
            Cancellation & Refund policy
          </div> */}
          <BreadCrumbs
            title="Home"
            title2="About"
            title3="Cancellation & Refund policy"
          />

          <div className="footer_heading">{t("Refund_policy.Cancellation_Refund.Refund_policy1")}</div>
          <br />
          <h5>
            <strong>*&nbsp;</strong>{t("Refund_policy.Cancellation_Refund.cancel_order")}
          </h5>
          <ul>
            <li>
            {t("Refund_policy.Cancellation_Refund.cancel_order_1")}
            </li>
            <li>{t("Refund_policy.Cancellation_Refund.cancel_order_2")}</li>
            <li>
            {t("Refund_policy.Cancellation_Refund.cancel_order_3")} {API.NAME} {t("Refund_policy.Cancellation_Refund.cancel_order_3b")}{" "}
              <a href={`mailto://${API.CONTACT_MAIL}`}>
                {" "}
                &nbsp;{API.CONTACT_MAIL}
              </a>
            </li>
          </ul>
          <h5>
            <strong>*&nbsp;</strong>{t("Refund_policy.Cancellation_Refund.incorrect_or_damaged")}
          </h5>
          <p>
          {t("Refund_policy.Cancellation_Refund.incorrect_or_damaged1")}{" "}
            {API.NAME} {" "}{t("Refund_policy.Cancellation_Refund.incorrect_or_damaged2")}
          </p>
          <h6>
          {t("Refund_policy.Cancellation_Refund.original_mode_of_payment")}
          </h6>
        </Col>
        <br />
      </Container>
    </div>
  );
};

export default Cancellation;
