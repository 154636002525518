import { Button, Form, Input, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

function ProductSubstituteModal({ open, close, data }: any) {
  const [form] = Form.useForm();
  const { Option } = Select;
  return (
    <Modal
      title="Request Product Substitution"
      open={open}
      centered
      onCancel={close}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={form.submit} loading={false}>
          Request Substitution
        </Button>,
      ]}
    >
      <Form form={form} name="basic" onFinish={(val) => {console.log(val)}} layout="vertical">
        <Form.Item
          rules={[{ required: true, message: "Please Select Product" }]}
          label="Select Product to Substitue"
          name={"product"}
        >
          <Select className="w-100" size="large">
            {data?.orderItems?.map((item: any, index: number) => {
              return (
                <Option value={item.productId} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Available Quantity"
          rules={[
            {
              required: true,
              message: "Please Input Available Quantity of Product",
            },
          ]}
          name={"quantity"}
        >
          <Input type="number" size="large" />
        </Form.Item>
        <Form.Item
          label="Your Remark"
          rules={[
            {
              required: true,
              message: "Please Enter Remakr.",
            },
          ]}
          name={"remark"}
        >
          <TextArea rows={2} placeholder="Enter remark" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ProductSubstituteModal;
