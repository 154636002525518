import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import API from "../../config/API";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../components/breadCrumbs";

const AccebilityStatement = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { t } = useTranslation();

  return (
    <div className="Screen-box">
      <Container>
        <br />
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          {/* <div className="heading_bredcume">
            Home <IoIosArrowForward /> About <IoIosArrowForward />
            Accessibility Statement
          </div> */}
          <BreadCrumbs
            title="Home"
            title2="About"
            title3="Accessibility Statement"
          />

          <Row>
            <Col md={6} xs={12}>
              <div className="footer_heading">{t("Accessibility_Statement.Accessibility.Accessibility_heading")}</div>
              <p>
              {t("Accessibility_Statement.Accessibility.digital_accessibility_1")}{API.NAME},{t("Accessibility_Statement.Accessibility.digital_accessibility_2")} (WCAG) 2.1,{t("Accessibility_Statement.Accessibility.digital_accessibility_3")}
              </p>
              <br />
              <h4> {t("Accessibility_Statement.Accessibility.Commitment_Accessibility")}</h4>
              <ul>
                <li>
                {t("Accessibility_Statement.Accessibility.Commitment_Accessibility_1")}
                </li>
                <li>
                {t("Accessibility_Statement.Accessibility.Commitment_Accessibility_2")}
                </li>{" "}
                <li>
                {t("Accessibility_Statement.Accessibility.Commitment_Accessibility_3")}
                </li>
                <li>
                {t("Accessibility_Statement.Accessibility.Commitment_Accessibility_4")}{" "}
                  <a href={`mailto://${API.CONTACT_MAIL}`}>
                    {API.CONTACT_MAIL}
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
        <br />
      </Container>
    </div>
  );
};

export default AccebilityStatement;
