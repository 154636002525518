import moment from "moment";
import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrderStatus } from "../../../shared/helpers/getOrderStatus";
import { Table, Pagination } from 'antd';
import API from "../../../config/API";

function OrderDataTable(props: any) {
  const navigate = useNavigate();
  const Settings = useSelector((state: any) => state.Settings.Settings);

  const columns = [
    {
      title: "OrderId",
      dataIndex: "order_id",
      key: "id",
    },
    {
      title: "User Name",
      dataIndex: "userDetails",
      key: "userId",
      render: (item: any) => <span>{item?.name}</span>,
    },
    {
      title: "OrderDate", //
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item: any) => <span>{moment(item).format("MMM Do YYYY")}</span>,
    },
    {
      title: "Total", //
      dataIndex: "total",
      key: "total",
      render: (item: any) => (
        <span>
          {Settings.currency}
          {item}
        </span>
      ),
    },
    {
      title: "Status", //
      dataIndex: "status",
      key: "status",
      render: (item: string) => <span>{getOrderStatus(item)}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      render: (_text: any, _record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <AiOutlineEye
            cursor="pointer"
            size={20}
            color={API.COLOR}
            onClick={() => {
              navigate(`/auth/Orders/${_record?.order_id}`);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        size="small"
        dataSource={props?.data?.length ? props.data : []}
        columns={columns}
        pagination={false}
      />
        <Pagination
          current={props?.page || 1}
          pageSize={props?.pageSize || 10}
          total={props?.meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            props?.changePage(current);
          }}
          style={{ marginTop: '20px', textAlign: 'center' }}
        />
    </div>
  );
}
export default OrderDataTable;
