import React from "react";
import PageHeader from "../components/PageHeader";
import { Tabs, TabsProps } from "antd";
import SettlementScreen1 from "./screens/settlementsc1";
import SettlementScreen2 from "./screens/settlementsc2";
import SettlementScreen3 from "./screens/settlementsc3";

function SettlementScreen() {
  const items: TabsProps["items"] = [
    {
      key: "order",
      label: "Order Summary",
      children: <SettlementScreen1 />,
    },
    {
      key: "settlement-summary",
      label: "Settlement Summary",
      children: <SettlementScreen3/>,
    },
    {
      key: "settlement",
      label: "Settlement History",
      children: <SettlementScreen2 />,
    },
  ];
  const onChange = (key: string) => {};
  return (
    <>
      <PageHeader title={`Settlements`}></PageHeader>
      <Tabs defaultActiveKey={"order"} items={items} onChange={onChange} />
    </>
  );
}

export default SettlementScreen;
