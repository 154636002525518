import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";

import API from "../../config/API";
import { GET } from "../../utils/apiCalls";

import Loader from "./components/laoder";
import Banners from "./components/banners";
import Offers from "./components/offers";
import PopularItems from "./components/popularItems";
import Loading from "../../components/loading";
import SubCategoryList from "./components/subCategoryList";
import SEO from "../../components/seo";
import React from "react";
import TopSellingStore from "./components/TopSellingStore";
import { LocationType } from "../../shared/types/types";
import FeaturedItems from "./components/featured_items";
import { jwtDecode } from "jwt-decode";

function HomeSCreen() {
  const Category = useSelector((state: any) => state?.Category?.categries);
  const [subCategories, setSubcategories] = useState<any[]>([]);
  const Settings = useSelector((state: any) => state?.Settings?.Settings);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const Location = useSelector((state: any) => state?.Location?.location);
  const lattitude = Settings?.isLocation === true ? Location.latitude : "";
  const longitude = Settings?.isLocation === true ? Location.longitude : "";
  const accessToken = useSelector((state: any) => state?.User?.user?.token);
  const Auth = useSelector((state: any) => state?.User?.auth);
  const [topRated, setTopRated] = useState<any[]>([]);
  const [history, setHistory] = useState<any[]>([]);
  const LocationDetails: LocationType = useSelector(
    (state: any) => state.Location.location
  );

  const [Banner, setBanners] = useState([]);
  const getRecentProducts = async () => {
    const url =
      API.PRODUCT_SEARCH_NEW_SINGLE +
      `?lattitude=${lattitude}&longitude=${longitude}&take=10&radius=${Settings?.radius}&tag=recent`;
    try {
      setLoading(true);
      const response: any = await GET(url, null);
      if (response.status) {
        setProducts(response.data);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const getTopRated = async () => {
    const url =
      API.PRODUCT_SEARCH_NEW_SINGLE +
      `?lattitude=${lattitude}&longitude=${longitude}&take=10&radius=${Settings?.radius}&tag=top`;
    try {
      setLoading(true);
      const response: any = await GET(url, null);
      if (response.status) {
        setTopRated(response?.data);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTopRated();
    getRecentProducts();
    getBanners();
    if (Auth) {
      getUserHistory();
    }
    window.scrollTo(0, 0);
  }, [Location]);

  const getAllSubcategories = async () => {
    const subcategories: any[] = [];
    const category_subcategory: string[] = [];
    Category?.forEach((item: any) => {
      category_subcategory.push(item.name);
      item?.sub_categories?.forEach((item: any) => {
        subcategories.push(item);
        category_subcategory.push(item.name);
      });
    });
    setSubcategories(subcategories);
  };

  const getBanners = async () => {
    const url =
      API.GET_HOMESCREEN +
      `lat=${lattitude}&long=${longitude}&radius=${Settings?.radius}`;
    try {
      const banners: any = await GET(url, null);
      if (banners.status) {
        setBanners(banners.data);
      }
    } catch (err) {
      console.log("failed to get banneers");
    }
  };

  const getUserHistory = async () => {
    const url = API.USER_HISTORY;
    try {
      let currentDate = new Date();
      if (!accessToken) return;
      const decoded: any = jwtDecode(accessToken);
      if (decoded.exp && decoded.exp * 1000 + 10000 > currentDate.getTime()) {
        const response: any = await GET(url, null);
        if (response?.status) {
          setHistory(response?.data);
        }
      }
    } catch (err) {}
  };

  useMemo(() => {
    getAllSubcategories();
  }, [Category]);

  return (
    <div className="Screen-box">
      <SEO
        title={API.NAME}
        description={`${API.NAME}! The leading powerhouse retailer of UAE`}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {Banner?.length ? <Banners data={Banner} /> : null}
          <div className="HomeSCreen-space" />
          {subCategories?.length ? (
            <SubCategoryList data={subCategories} />
          ) : null}

          {LocationDetails.latitude &&
          LocationDetails.longitude &&
          Settings?.isLocation &&
          Settings?.type === "multi" ? (
            <>
              <div className="HomeSCreen-space" />
              <TopSellingStore />
            </>
          ) : (
            <>
              <div className="HomeSCreen-space" />
              <br />
              <Offers data={Banner.slice(3, 10)} />
            </>
          )}
          {loading ? (
            <Loading />
          ) : products?.length > 0 ? (
            <>
              <div className="HomeSCreen-space" />
              <PopularItems
                data={products}
                title="Recently Arrived Products"
                type="recent"
              />
            </>
          ) : null}
          <div className="HomeSCreen-space" />
          <FeaturedItems />
          {loading ? (
            <Loading />
          ) : topRated?.length > 0 ? (
            <>
              <div className="HomeSCreen-space" />{" "}
              <PopularItems
                data={topRated}
                title="Top Rated Products"
                type="toprated"
              />
            </>
          ) : null}
          {loading ? (
            <Loading />
          ) : history?.length > 0 && Auth ? (
            <>
              <div className="HomeSCreen-space" />{" "}
              <PopularItems
                data={history}
                title="Recently Visited Products"
                type="visited"
              />
            </>
          ) : null}
        </>
      )}

      <br />
    </div>
  );
}
export default HomeSCreen;
