import React, { useState } from "react";
import { Badge, Pagination, Popconfirm, Table, notification } from "antd";
import { AiTwotoneDelete } from "react-icons/ai";
import API from "../../config/API";
import { DELETE, PUT } from "../../utils/apiCalls";
import { BiSolidEditAlt } from "react-icons/bi";
import "./style.scss";
import { useSelector } from "react-redux";
import { MdViewCarousel } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { FaList } from "react-icons/fa6";
import { ExecuteFunction } from "../../shared/helpers/executeFunction";

function DataTable(props: any) {
  const [Notifications, contextHolder] = notification.useNotification();
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  const Role = useSelector((state: any) => state.User?.user?.data?.role);
  const StoreId = useSelector((state: any) => state.User?.user?.data?.store_id);
  const itemDeleteHandler = async (item: any) => {
    const url = API.BANNER + item?.id;
    try {
      const response: any = await DELETE(url);
      if (response.status) {
        Notifications["success"]({
          message: "Success",
          description: response.message,
        });
        props?.getBannerData(props?.page);
      } else {
        Notifications["error"]({
          message: "Failed to Delete",
          description: response.message,
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Failed to Delete",
        description: err.message,
      });
    }
  };

  const handleImageClick = (imageUrl: string) => {
    setEnlargedImage(imageUrl);
  };

  const closeEnlargedImage = () => {
    setEnlargedImage(null);
  };
  const bannerStatusChangeHandler = async (item: any) => {
    const url = API.BANNER_STATUS_UPDATE + item?.id;
    try {
      const response: any = await PUT(url, {});
      if (response.status) {
        Notifications["success"]({
          message: "Success",
          description: response.message,
        });
        props?.getBannerData(props?.page);
      } else {
        Notifications["error"]({
          message: response.message ?? "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong",
      });
    }
  };
  const columns = [
    {
      title: "",
      dataIndex: "position",
      key: "position",
      render: (position: any, _record: any) => (
        <Badge count={position} showZero size="small" color={API.COLOR}>
          <FaList
            cursor="pointer"
            size={20}
            color={"grey"}
            onClick={() => {
              ExecuteFunction(props?.openPosition);
              ExecuteFunction(props?.setPosition, _record);
            }}
          />
        </Badge>
      ),
    },
    {
      title: "Image",
      dataIndex: "img_desk",
      key: "img_desk",
      render: (item: string) => (
        <div className="image-preview-container">
          <img
            alt={"image"}
            src={item}
            className="banner-table-card-image"
            onClick={() => handleImageClick(item)}
          />
        </div>
      ),
    },
    { title: "Title", dataIndex: "title", key: "title" },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      key: "actions",
      width: 90,
      render: (text: any, record: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {StoreId == record?.storeId ? <></> : null}
          <BiSolidEditAlt
            cursor="pointer"
            size={20}
            color="grey"
            onClick={() => {
              props?.changeType();
              props?.getSelectedItem(record);
              props?.openModal();
            }}
          />
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => itemDeleteHandler(record)}
          >
            <AiTwotoneDelete cursor="pointer" size={20} color={API.COLOR} />
          </Popconfirm>
          {Role == "admin" ? (
            <Popconfirm
              placement="bottomRight"
              title={
                record.status == true
                  ? `Remove banner from ${API.NAME} Home?`
                  : `Make this banner available on ${API.NAME} Home`
              }
              okText="Yes"
              cancelText="No"
              onConfirm={() => bannerStatusChangeHandler(record)}
            >
              <MdViewCarousel cursor="pointer" size={20} color={API.COLOR} />
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];
  if (Role == "admin") {
    columns.splice(1, 0, {
      title: "Store",
      dataIndex: "storeDetails",
      key: "storeDetails",
      render: (item: any) => <span>{item?.store_name ?? ""}</span>,
    });
    columns.splice(4, 0, {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item: any) => (
        <span>{item == true ? <TiTick color="green" size={20} /> : ""}</span>
      ),
    });
  }

  return (
    <div>
      {contextHolder}
      <Table
        size="small"
        dataSource={props.data}
        columns={columns}
        rowKey={(data: any) => data?.id}
        pagination={false}
      />
      {/* Enlarged Image Preview */}
      {enlargedImage && (
        <div className="enlarged-image-container">
          <div
            className="enlarged-image-backdrop"
            onClick={closeEnlargedImage}
          />
          <div className="enlarged-image-content">
            <img
              src={enlargedImage}
              alt="Enlarged Preview"
              className="enlarged-image"
            />
          </div>
        </div>
      )}

      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={props?.page || 1}
          pageSize={props?.pageSize || 10}
          total={props?.meta?.itemCount || 10}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            props?.changePage(current, size);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
