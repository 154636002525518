import React from "react";
import { Breadcrumb } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

interface BreadCrumbProps {
  title?: string;
  title2?: string;
  title3?: string;
}
const BreadCrumbs = (props: BreadCrumbProps) => {
  const { title, title2, title3 } = props;
  const navigate = useNavigate();
  return (
    <div className="mb-3">
      <Breadcrumb
        style={{ cursor: "pointer" }}
        separator={<RightOutlined style={{ fontSize: "10px" }} color="grey" />}
      >
        <Breadcrumb.Item onClick={() => navigate("/")}>{title}</Breadcrumb.Item>
        <Breadcrumb.Item>{title2}</Breadcrumb.Item>
        <Breadcrumb.Item>{title3}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumbs;
