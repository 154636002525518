import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GET } from "../../../utils/apiCalls";
import API from "../../../config/API";
import AdminLoading from "../../components/AdminLoading";
import NoData from "../../../components/noData";
import { BsFillBookmarkFill } from "react-icons/bs";
import { List } from "antd";

function Address() {
  const [addressDetails, setAddressDetails] = useState<any>();
  const [loading, setLoading] = useState(true);

  const getAddressDetails = async () => {
    setLoading(true);
    const url = API.ADDRESS_GET;
    try {
      const response: any = await GET(url, null);
      console.log(response, "API RESPONSE");
      if (response?.status) {
        setAddressDetails(response?.data || []);
      }
      // setAddressDetails(response);
    } catch (err: any) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAddressDetails();
  }, []);

  return (
    <>
      {loading ? (
        <AdminLoading />
      ) : addressDetails.length ? (
        <List
          className="profilescreen-address-lists"
          itemLayout="horizontal"
          dataSource={addressDetails}
          renderItem={(item: any, index) => (
            <List.Item>
              <div className="profilescreen-address-card">
                <span className="profilescreen-address-type text-uppercase">
                  {item.type}
                </span>

                <div className="profilescreen-address-name-phoneno">
                  <span> {item.userDetails.name}</span>
                  <span className="profilescreen-address-phoneno">
                    {item.code ?? ""} {item.alt_phone}
                  </span>
                </div>
                <div className="profilescreen-address-fulladdress">
                  {item.fullAddress}
                </div>
                <div className="profilescreen-address-formaddress">
                  {item.flat}, {item.street}, {item.city}, {item.state} ,
                  <span className="fw-bold"> {item.pin_code}</span>
                </div>
              </div>
            </List.Item>
          )}
        />
      ) : (
        <NoData
          icon={<BsFillBookmarkFill size={70} color="#e6e6e6" />}
          header="No Address Found"
          text1={`You have not added any Address yet!`}
        />
      )}
    </>
  );
}
export default Address;
