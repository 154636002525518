import React from "react";
import { Tabs, TabsProps } from "antd";
import { Container } from "react-bootstrap";
import PageHeader from "../../components/PageHeader";
import Address from "./address";
import Orders from "./orders";
import Details from "./details";

function UserInfo(props: any) {
  const items: TabsProps["items"] = [
    {
      key: "details",
      label: "Details",
      children: <Details />,
    },
    {
      key: "address",
      label: "Address",
      children: <Address />,
    },
    {
      key: "orders",
      label: "Orders",
      children: <Orders />,
    },
  ];
  const onChange = (key: string) => {};

  return (
    <div>
      <PageHeader title="User Info"></PageHeader>
      <Container>
        <Tabs defaultActiveKey={"details"} items={items} onChange={onChange} />
      </Container>
    </div>
  );
}
export default UserInfo;
