import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Collapse } from "antd";
import API from "../../config/API";
import { IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { FaMinus, FaPlus } from "react-icons/fa6";
import BreadCrumbs from "../../components/breadCrumbs";


function Faq() {
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const { t } = useTranslation();
  const { Panel } = Collapse;
  
  const toggleTerms = (key: string | string[]) => {
    if (Array.isArray(key)) {
      setActiveKey(key);
    } else {
      setActiveKey(activeKey.includes(key) ? [] : [key]);
    }
  };

  const faqData = [
    {
      question:
        t("faqs_page.faqs.products_online_via"),
      answer: [
        <>
          <li key={0}>
            <p>
            {t("faqs_page.faqs.products_online_via_1a")}
              <a
                href={`https://www.${API.WEBSITE}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                &nbsp; {API.WEBSITE}
                &nbsp;{" "}
              </a>
              {t("faqs_page.faqs.products_online_via_1b")}
              {API.WEBSITE} {t("faqs_page.faqs.products_online_via_1c")}
            </p>
          </li>
          <li key={1}>
            <p>
            {t("faqs_page.faqs.products_online_via_2a")}{API.NAME};
            {t("faqs_page.faqs.products_online_via_2b")}
            </p>
          </li>
        </>,
      ],
    },
    {
      question:  t("faqs_page.faqs.check_warranty_details_a")+ " " + API.NAME + " " + t("faqs_page.faqs.check_warranty_details_b"),
      answer: [
        <p key={0}>
          {t("faqs_page.faqs.check_warranty_details_1a")}{" "}
          <a
            href={`https://www.${API.WEBSITE}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {API.WEBSITE}
          </a>{" "}
          {t("faqs_page.faqs.check_warranty_details_1b")} {API.NAME}
          {t("faqs_page.faqs.check_warranty_details_1c")}
        </p>,
      ],
    },
    {
      question:
      t("faqs_page.faqs.warranty_check_number"),
      answer: [
        t("faqs_page.faqs.warranty_check_number1"),
      ],
    },
    {
      question:
      t("faqs_page.faqs.invoice_details"),
      answer: [
        t("faqs_page.faqs.invoice_details1"),
        t("faqs_page.faqs.invoice_details2"),
      ],
    },
    {
      question:
      t("faqs_page.faqs.Application_portal"),
      answer: [
        t("faqs_page.faqs.Application_portal1"),
      ],
    },
    {
      question:
      t("faqs_page.faqs.incorrect_warranty_application"),
      answer: [
        <>
          <li key={0}>
            <p>
             {t("faqs_page.faqs.incorrect_warranty_application_1a")} {API.NAME} {t("faqs_page.faqs.incorrect_warranty_application_1b")}{" "}
              {API.NAME}
              {t("faqs_page.faqs.incorrect_warranty_application_1c")}
            </p>
          </li>
          <li key={1}>
            <p>
            {t("faqs_page.faqs.incorrect_warranty_application_2a")}{" "}
              <a href="/cancellation_return">
              {t("faqs_page.faqs.incorrect_warranty_application_2b")} – {API.NAME} UAE
              </a>{" "}
              {t("faqs_page.faqs.incorrect_warranty_application_2c")}
            </p>
          </li>
        </>,
      ],
    },

    {
      question: t("faqs_page.faqs.nextme_contact_center_a")+ " " + API.NAME + " " +t("faqs_page.faqs.nextme_contact_center_b") ,
      answer: [
        t("faqs_page.faqs.nextme_contact_center1"),
      ],
    },
    {
      question:
      t("faqs_page.faqs.log_warranty_claim"),
      answer: [
        t("faqs_page.faqs.log_warranty_claim1a") + " " + API.NAME + " " + t("faqs_page.faqs.log_warranty_claim1b"),
      ],
    },
    {
      question:
      t("faqs_page.faqs.status_warranty_ticket"),
      answer: [
        t("faqs_page.faqs.status_warranty_ticket1"),
      ],
    },
    {
      question:
      t("faqs_page.faqs.purchase_additional_warranty"),
      answer: [
        t("faqs_page.faqs.purchase_additional_warranty1"),
      ],
    },
    {
      question:
      t("faqs_page.faqs.unable_to_log_using_number"),
      answer: [
        t("faqs_page.faqs.unable_to_log_using_number1"),
      ],
    },
    {
      question: t("faqs_page.faqs.Warranty_in_other_languages"),
      answer: [
        t("faqs_page.faqs.Warranty_in_other_languages1"),
      ],
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Screen-box">
      <Container>
        <br />
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          {/* <div className="heading_bredcume">
            Home <IoIosArrowForward /> Support <IoIosArrowForward /> FAQs
          </div> */}
          <BreadCrumbs title="Home" title2="Support" title3="FAQs" />
          <Row>
            <div className="footer_heading">{t("faqs_page.faqs.frequently_asked")}</div>
            <br />
            <br />
            <Col md={12}>
              <Collapse
                accordion
                activeKey={activeKey}
                onChange={(key) => toggleTerms(key)}
                expandIconPosition={"end"}
                expandIcon={({ isActive }) =>
                  isActive ? <FaMinus /> : <FaPlus />
                }
              >
                {faqData?.map((faq, index) => (
                  <Panel
                    key={index.toString()}
                    showArrow={true}
                    header={
                      <p
                        className={`fs-6 ${
                          activeKey[0] === index.toString() ? "fw-bold" : ""
                        }`}
                        onClick={() => toggleTerms(index.toString())}
                      >
                        {faq?.question}
                      </p>
                    }
                  >
                    <ul>
                      {faq.answer.map((item, i) => (
                        <li key={`faq_${i}`}>
                          {Array.isArray(item)
                            ? item.map((subItem, subIndex) => (
                                <React.Fragment
                                  key={`faq_${i}_sub_${subIndex}`}
                                >
                                  {subItem}
                                </React.Fragment>
                              ))
                            : item}
                        </li>
                      ))}
                    </ul>
                  </Panel>
                ))}
              </Collapse>
            </Col>
          </Row>
        </Col>
        <br />
      </Container>
    </div>
  );
}

export default Faq;
