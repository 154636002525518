import { Input, Button, Form, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { IoMailOutline } from "react-icons/io5";
import { PiPhoneCall } from "react-icons/pi";
import API from "../../config/API";
import { POST } from "../../utils/apiCalls";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import { IoIosArrowForward } from "react-icons/io";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../components/breadCrumbs";

function ContactUs() {
  const map = ` <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462561.6574537445!2d55.22748795!3d25.076022449999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1713953210257!5m2!1sen!2sin" width="100%" height="300" style="border:0;border-radius:10px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [form] = useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const Settings = useSelector((state: any) => state.Settings.Settings);

  const [notificationApi, contextHolder] = notification.useNotification();
  const OnFinsh = async (values: any) => {
    try {
      setIsLoading(true);
      let url = API.ENQUIRY_CREATE;
      let Obj = {
        subject: values?.subject,
        name: values?.name,
        phone: values?.phone,
        email: values?.email,
        message: values?.message,
      };
      let response: any = await POST(url, Obj);
      console.log("response", response);
      if (response.status) {
        notificationApi.success({ message: "Succesfully Submitted" });

        form.resetFields();
      } else {
        notificationApi.error({ message: "Failed to Submit Request" });
      }
    } catch (error) {
      console.error("error");
    } finally {
      setIsLoading(false);
    }
  };
  const { Option } = Select;

  return (
    <div className="Screen-box">
      <Container>
        <br />
        {contextHolder}

        <Col md={{ span: 10, offset: 1 }} xs={12}>
          {/* <div className="heading_bredcume">
            Home <IoIosArrowForward /> Support <IoIosArrowForward /> Contact Us
          </div> */}
          <BreadCrumbs title="Home" title2="Support" title3="Contact Us" />

          <Row>
            <Col md={6} xs={12}>
              <div className="footer_heading">{t("contact_us1.contact.contact_us_a")}</div>
              <div dangerouslySetInnerHTML={{ __html: map }} />
              <div className="ContactUs_Box1">
                <div className="icon_box2">
                  <div className="icon_box1">
                    <AiOutlineWhatsApp size={32} color="green" />
                  </div>
                  <div>
                    <div>Whatsapp</div>
                    <a
                      href={`https://wa.me/${Settings?.contactNumber}`}
                      target="_blank"
                      rel="noreferrer"
                      className="Contact_text2"
                    >
                      {Settings?.contactNumber}
                    </a>
                  </div>
                </div>
                <div className="icon_box2">
                  <div className="icon_box1">
                    <PiPhoneCall size={32} color="#3c4b9e" />
                  </div>
                  <div>
                    <div>Customer Support</div>
                    <a
                      href={`tel:${Settings?.contactNumber}`}
                      target="_blank"
                      rel="noreferrer"
                      className="Contact_text2"
                    >
                      {Settings?.contactNumber}
                    </a>
                  </div>
                </div>
                <div className="icon_box2">
                  <div className="icon_box1">
                    <IoMailOutline size={32} color="#000" />{" "}
                  </div>
                  <div>
                    <div>Mail</div>
                    <a
                      href={`mailto:${Settings?.contactEmail}`}
                      target="_blank"
                      rel="noreferrer"
                      className="Contact_text2"
                    >
                      {Settings?.contactEmail}
                    </a>
                  </div>
                </div>
                <div className="icon_box2">
                  <div className="icon_box1">
                    <HiOutlineOfficeBuilding size={32} color="#ba6c6c" />
                  </div>
                  <div>
                    <div>Office</div>
                    <a className="Contact_text2">{Settings?.address}</a>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={1} />
            <Col md={5} xs={12}>
              <div>
                <div className="Contact_text1">{t("contact_us1.contact.send_message")}</div>
                <Form onFinish={OnFinsh} form={form}>
                  <div className="ContactUs_text2">{t("contact_us1.contact.subject")}</div>
                  <Form.Item
                    name="subject"
                    rules={[
                      { required: true, message: "Please select an Option" },
                    ]}
                  >
                    <Select size="large" placeholder="Select an Option">
                      <Option value="booking">Booking</Option>
                      <Option value="orders">Orders</Option>
                      <Option value="services">Services</Option>
                      <Option value="others">Others</Option>
                    </Select>
                  </Form.Item>
                  <div className="Contact_text2">{t("contact_us1.contact.name")}</div>
                  <Form.Item name="name">
                    <Input size="large" placeholder="Your Name" required />
                  </Form.Item>
                  <div className="Contact_text2">{t("contact_us1.contact.phone")}</div>
                  <Form.Item name="phone">
                    <Input
                      size="large"
                      placeholder="Phonenumber"
                      type="number"
                      required
                    />
                  </Form.Item>
                  <div className="Contact_text2">{t("contact_us1.contact.email")}</div>
                  <Form.Item name="email">
                    <Input
                      size="large"
                      placeholder="Email"
                      type="email"
                      required
                    />
                  </Form.Item>
                  <div className="Contact_text2">{t("contact_us1.contact.message")}</div>
                  <Form.Item name="message">
                    <Input.TextArea
                      size="large"
                      rows={3}
                      placeholder="Type your message..."
                    />
                  </Form.Item>

                  <Col md={{ offset: 6, span: 6 }} xs={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      loading={isLoading}
                      style={{ width: "100%" }}
                    >
                      Send
                    </Button>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
        <br />
        <br />
      </Container>
    </div>
  );
}

export default ContactUs;
