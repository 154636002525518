import React from "react";
import { IconType } from "react-icons";
import { CgUnavailable } from "react-icons/cg";
import { SlHome } from "react-icons/sl";
import { PiShoppingCartSimple } from "react-icons/pi";
import { CgProfile } from "react-icons/cg";
import { FiUsers } from "react-icons/fi";
import { LuLayoutDashboard } from "react-icons/lu";
import { IoMdDocument } from "react-icons/io";
import { RiSettings5Fill } from "react-icons/ri";
import { HiUsers } from "react-icons/hi2";
import { FaWpforms, FaChartPie, FaThList, FaRegImage } from "react-icons/fa";
import { MdSpaceDashboard, MdCategory } from "react-icons/md";
import { IoStorefrontSharp, IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { BiSolidCategoryAlt, BiSolidOffer } from "react-icons/bi";
import { BsFileLock2Fill, BsFillGridFill } from "react-icons/bs";
import { FaFileInvoice, FaStore, FaCalculator  } from "react-icons/fa6";
function DynamicIcon(props: any) {
  type IconName =
    | "PiShoppingCartSimple"
    | "LuLayoutDashboard"
    | "CgUnavailable"
    | "SlHome"
    | "FiUsers"
    | "CgProfile"
    | "FaWpforms"
    | "FaChartPie"
    | "MdSpaceDashboard"
    | "IoMdDocument"
    | "IoStorefrontSharp"
    | "MdCategory"
    | "BiSolidCategoryAlt"
    | "BsFileLock2Fill"
    | "FaFileInvoice"
    | "RiSettings5Fill"
    | "HiUsers"
    | "IoChatbubbleEllipsesSharp"
    | "FaThList"
    | "FaRegImage"
    | "BsFillGridFill"
    | "BiSolidOffer"
    | "FaCalculator"
    | "FaStore";

  interface IconProps {
    iconName: IconName;
    size?: number;
    color?: string;
  }
  function Icon({ iconName, size = 26, color = "red" }: IconProps) {
    const icons: Record<IconName, IconType> = {
      PiShoppingCartSimple: PiShoppingCartSimple,
      LuLayoutDashboard: LuLayoutDashboard,
      CgUnavailable: CgUnavailable,
      SlHome: SlHome,
      FaWpforms: FaWpforms,
      FiUsers: FiUsers,
      CgProfile: CgProfile,
      FaChartPie: FaChartPie,
      MdSpaceDashboard: MdSpaceDashboard,
      IoMdDocument: IoMdDocument,
      IoStorefrontSharp: IoStorefrontSharp,
      MdCategory: MdCategory,
      BiSolidCategoryAlt: BiSolidCategoryAlt,
      BsFileLock2Fill: BsFileLock2Fill,
      RiSettings5Fill: RiSettings5Fill,
      FaFileInvoice : FaFileInvoice ,
      HiUsers: HiUsers,
      IoChatbubbleEllipsesSharp: IoChatbubbleEllipsesSharp,
      FaThList: FaThList,
      FaRegImage: FaRegImage,
      BsFillGridFill: BsFillGridFill,
      BiSolidOffer: BiSolidOffer,
      FaCalculator: FaCalculator,
      FaStore: FaStore,
    };
    if (!icons.hasOwnProperty(iconName)) {
      console.warn(
        `Icon '${iconName}' not found. Rendering default icon instead.`
      );
      iconName = "CgUnavailable"; // set default icon name
    }
    const IconComponent = icons[iconName];
    return <IconComponent size={size} color={props.color} />;
  }
  return <Icon iconName={props.name} size={props.size} />;
}
export default DynamicIcon;
