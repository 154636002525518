import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { RiFacebookCircleLine } from "react-icons/ri";
import { BiLogoInstagram } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { LiaWhatsapp } from "react-icons/lia";
import Logo from "../../config/logo.png";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import API from "../../config/API";
import "./styles.scss";
import appstore from "../../assets/images/appstore.svg";
import googleplay from "../../assets/images/googleplay.svg";
const Footer = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  return (
    <div className="footer">
      <Container>
        <main className="footer-container">
          <Row className="g-3 g-sm-0">
            <Col md={3} xs={12}>
              <div className="Footer_text1">{t("contact_us")}</div>
              <div className="Footer_text2">
                <Link to="FAQ_s">FAQs</Link>
              </div>
              <div className="Footer_text2">
                <Link to="contact_us" style={{ textDecoration: "none" }}>
                  {t("contact")}
                </Link>
              </div>

              <div className="Footer_text2">
                <a href={`mailto:${API.CONTACT_MAIL}`}>{API.CONTACT_MAIL}</a>
              </div>
              <div className="Footer_text2">
                <a href="tel:+917001800600">{API.CONTACT_NUMBER}</a>
              </div>
              <div className="Footer_text2">
                <a
                  href="https://maps.app.goo.gl/ZVAuMttmfHSE3p8h6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong> Office:</strong> Dubai, United Arab Emirates
                </a>
              </div>
            </Col>
            <Col md={2} xs={12}>
              <div className="Footer_text1">{t("terms_policy")}</div>
              <div className="Footer_text2">
                <Link to="privacy-policy">{t("privacy_policy")}</Link>
              </div>
              <div className="Footer_text2">
                <Link to="terms_of_service">{t("terms_conditons")}</Link>
              </div>

              <div className="Footer_text2">
                <Link to="cancellation_return">{t("cancel_refund")}</Link>
              </div>
              <div className="Footer_text2">
                <Link to="accessibility_statement">
                  Accessibility Statement
                </Link>
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div className="list-container ">
                <div className="list-heading">{t("payment_methrd")}</div>
                <div className="Payment-cards">
                  <img
                    src={require("../../assets/images/visa.png")}
                    className="payment_icons"
                    alt="visacard"
                  />
                  <img
                    src={require("../../assets/images/mastercard.png")}
                    className="payment_icons"
                    alt="mastercard"
                  />
                  <img
                    src={require("../../assets/images/dinners.png")}
                    className="payment_icons"
                    alt="pay"
                  />
                  <img
                    src={require("../../assets/images/samsungpay.png")}
                    // className="payment_icons"
                    style={{ width: "33px", height: "33px", marginTop: "4px" }}
                    alt="samsungPay"
                  />
                </div>
                <br />
                <div className="list-heading mb-2">{t("download_app")}</div>
                <div className="Footer_download_Box">
                  <a
                    href="https://apps.apple.com/in/app/next-me/id6504331008"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={appstore}
                      alt="appstore"
                      className="Footer_download_Icons"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.nextmeapp"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={googleplay}
                      alt="google play store"
                      className="Footer_download_Icons"
                    />
                  </a>
                </div>
              </div>
            </Col>
            <Col md={3} xs={12}>
              <div className="logoContainer">
                <img
                  className="listFooter-logo"
                  src={"/nextmelogo.jpg"}
                  alt="logo"
                  onClick={() => navigation("/")}
                />
                <div className="social_icons">
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    className="Footer_text3"
                    rel="noreferrer"
                  >
                    <RiFacebookCircleLine size={25} color="#000" /> Facebook
                  </a>
                  <a
                    href="https://www.instagram.com"
                    target="_blank"
                    className="Footer_text3"
                    rel="noreferrer"
                  >
                    <BiLogoInstagram size={25} color="#000" /> Instagram
                  </a>
                  <a
                    href="https://www.twitter.com"
                    target="_blank"
                    rel="noreferrer"
                    className="Footer_text3"
                  >
                    {" "}
                    <FaXTwitter size={22} color="#000" /> Twitter
                  </a>
                  <a
                    href="https://wa.me/9876543210"
                    target="_blank"
                    rel="noreferrer"
                    className="Footer_text3"
                  >
                    <LiaWhatsapp size={24} color="#000" /> WhatsApp
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </main>
      </Container>
      <div className="Footer_Box2">
        <Container>
          <div className="copyright-txt">
            COPYRIGHT © 2024 {API.NAME} LLC. ALL RIGHTS RESERVED
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
