import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MdOutlineLocationOn } from "react-icons/md";
import ChooseLocationModal from "./components/locationChooseModal";
import useToggle from "../../shared/hook/useToggle";
import { LiaGreaterThanSolid } from "react-icons/lia";
import "./styles.scss";
import React from "react";
import API from "../../config/API";

const LocationMobile = () => {
  const LocationDetails = useSelector((state: any) => state.Location.location);
  const [openLocation, toggleLocation] = useToggle(false);
  const { t } = useTranslation();
  return (
    <div className="LocationMobile-Box1">
      <div className="LocationMobile-Txt1" onClick={() => toggleLocation(true)}>
        <MdOutlineLocationOn size={20} color="grey" />
        &nbsp;
        <div style={{ color: API.COLOR }}>
          Delivery to &nbsp;
          <LiaGreaterThanSolid size={10} color="grey" />
        </div>
        <div className="LocationMobile-Txt2">
          &nbsp;
          {LocationDetails.latitude
            ? LocationDetails.full_address
              ? LocationDetails?.full_address
              : LocationDetails.postal_code
            : "Select Location"}
        </div>
      </div>
      {openLocation ? (
        <ChooseLocationModal
          open={openLocation}
          modalClose={() => toggleLocation(false)}
        />
      ) : null}
    </div>
  );
};

export default LocationMobile;
