import React, { useEffect } from "react";
import "./styles.scss";
import { Col, Container } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import API from "../../config/API";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../components/breadCrumbs";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  return (
    <div className="Screen-box">
      <Container>
        <br />
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          {/* <div className="heading_bredcume">
            Home <IoIosArrowForward /> About <IoIosArrowForward /> Privacy and
            Policy
          </div> */}
          <BreadCrumbs
            title="Home"
            title2="About"
            title3="Privacy and
            Policy"
          />
          <div className="footer_heading">{t("Privacy.privacy_policy.privacy_policy1")}</div>
          <br />
          <strong>{API.NAME} {t("Privacy.privacy_policy.nxt_privacy_policy")}</strong>
          <p>
          {t("Privacy.privacy_policy.nxt_privacy_policy1a")} {API.NAME}! {t("Privacy.privacy_policy.nxt_privacy_policy_1b")} {API.NAME} {t("Privacy.privacy_policy.nxt_privacy_policy_1c")}
          </p>
          <br />
          {t("Privacy.privacy_policy.nxt_privacy_policy_2a")} {API.NAME}’s {t("Privacy.privacy_policy.nxt_privacy_policy_2b")} {API.NAME} {t("Privacy.privacy_policy.nxt_privacy_policy_2c")}
          <br />
          <br />
          <div className="Policy_Text2">{t("Privacy.privacy_policy.information_we_collect")} </div>
          <li>
          {t("Privacy.privacy_policy.information_we_collect1")}
          </li>
          <p>
          {t("Privacy.privacy_policy.information_we_collect1a")} {API.NAME}{t("Privacy.privacy_policy.information_we_collect1b")} {API.NAME}, {t("Privacy.privacy_policy.information_we_collect1c")} {API.NAME}, {t("Privacy.privacy_policy.information_we_collect1d")}
          </p>
          <p>
          {t("Privacy.privacy_policy.information_we_collect1e")} {API.NAME} {t("Privacy.privacy_policy.information_we_collect1f")} {API.NAME} {t("Privacy.privacy_policy.information_we_collect1g")} {API.NAME} {t("Privacy.privacy_policy.information_we_collect1h")}
          </p>
          <li>{t("Privacy.privacy_policy.information_we_collect2")} {API.NAME}</li>
          <p>
          {t("Privacy.privacy_policy.information_we_collect2a")} {API.NAME} {t("Privacy.privacy_policy.information_we_collect2b")}
          </p>
          <div className="Policy_Text2">
          {t("Privacy.privacy_policy.cookies_and_other")}{" "}
          </div>
          <p>
          {t("Privacy.privacy_policy.cookies_and_other1")} {API.NAME}.
          </p>
          <p>
          {t("Privacy.privacy_policy.cookies_and_other2a")} {API.NAME}, {t("Privacy.privacy_policy.cookies_and_other2b")}{" "}
            <a
              href="http://www.google.com/policies/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Google Privacy Policy
            </a>
            .
          </p>
          <div className="Policy_Text2"> {t("Privacy.privacy_policy.log_information")}</div>
          <p>
          {t("Privacy.privacy_policy.log_information1")} {API.NAME}, {t("Privacy.privacy_policy.log_information1a")} {API.NAME}, {t("Privacy.privacy_policy.log_information1b")} {API.NAME} {t("Privacy.privacy_policy.log_information1c")}
          </p>
          <p>
          {t("Privacy.privacy_policy.log_information1d")}
          </p>
          <div className="Policy_Text2">{t("Privacy.privacy_policy.use_your_info")}</div>
          {t("Privacy.privacy_policy.use_your_info1")}
          <ol>
            <li>
              {" "}
              {t("Privacy.privacy_policy.use_your_info1a")} {API.NAME} {t("Privacy.privacy_policy.use_your_info1a1")}
            </li>

            <li>
              {" "}
              {t("Privacy.privacy_policy.use_your_info1b")}
            </li>

            <li>
            {t("Privacy.privacy_policy.use_your_info1c")}
            </li>

            <li>
            {t("Privacy.privacy_policy.use_your_info1d")}
            </li>

            <li>
              {" "}
              {t("Privacy.privacy_policy.use_your_info1e")} {API.NAME} {t("Privacy.privacy_policy.use_your_info1e2")}
            </li>

            <li>
            {t("Privacy.privacy_policy.use_your_info1f")} {API.NAME} {t("Privacy.privacy_policy.use_your_info1f2")}{" "}
              {API.NAME} {t("Privacy.privacy_policy.use_your_info1f3")}
            </li>
          </ol>
          <br />
          <strong>{t("Privacy.privacy_policy.what_we_share")}</strong>
          <br />
          {API.NAME} {t("Privacy.privacy_policy.what_we_share_a")}
          <br />
          {t("Privacy.privacy_policy.what_we_share_b")}
          <br />
          <br />
          <ol>
            <li>
              · {t("Privacy.privacy_policy.what_we_share_1")} {API.NAME} {t("Privacy.privacy_policy.what_we_share_1a")}
            </li>
            <li>
            {t("Privacy.privacy_policy.what_we_share_2")}
            </li>
            <li>
            {t("Privacy.privacy_policy.what_we_share_3")} {API.NAME}, {t("Privacy.privacy_policy.what_we_share_3a")}
            </li>
            <li>
            {t("Privacy.privacy_policy.what_we_share_4")} {API.NAME} {t("Privacy.privacy_policy.what_we_share_4a")}
            </li>
            <li>
            {t("Privacy.privacy_policy.what_we_share_5")} {API.NAME} {t("Privacy.privacy_policy.what_we_share_5a")}
            </li>
            <li>
            {t("Privacy.privacy_policy.what_we_share_6")} {API.NAME}.
            </li>
            <li>
            {t("Privacy.privacy_policy.what_we_share_7")}
            </li>
            <li>
            {t("Privacy.privacy_policy.what_we_share_8")} {API.NAME}, {t("Privacy.privacy_policy.what_we_share_8a")}
            </li>
          </ol>
          <strong>{t("Privacy.privacy_policy.applicable_mena_region")}</strong>
          <br />
          <br />
          <p>
          {t("Privacy.privacy_policy.applicable_mena_region1")}{" "}
          </p>
          <p>
          {t("Privacy.privacy_policy.applicable_mena_region2")}
          </p>{" "}
          <p>
          {t("Privacy.privacy_policy.applicable_mena_region3")}{" "}
            {API.NAME}. {t("Privacy.privacy_policy.applicable_mena_region3a")}
          </p>
          <br />
          <p>
          {t("Privacy.privacy_policy.applicable_mena_region4")} {API.NAME}
          {t("Privacy.privacy_policy.applicable_mena_region4a")}
          </p>
          <br />
          <p>
          {t("Privacy.privacy_policy.applicable_mena_region5")}
          </p>
          <br />
          <br />
          <strong> {API.NAME} {t("Privacy.privacy_policy.nextmeapp")}</strong>
          <br />
          {t("Privacy.privacy_policy.nextmeapp_a")} {API.NAME} {t("Privacy.privacy_policy.nextmeapp_b")}
          <div className="Policy_Text2">{t("Privacy.privacy_policy.nextme_app")}</div>
          <p>
          {t("Privacy.privacy_policy.nextme_app_a")} {API.NAME}’s {t("Privacy.privacy_policy.nextme_app_b")} {API.NAME} {t("Privacy.privacy_policy.nextme_app_c")}
          </p>
          <ol>
            <li>
            {t("Privacy.privacy_policy.nextme_app_1")}
            </li>
            <li>
            {t("Privacy.privacy_policy.nextme_app_2")}
              <br />
              {t("Privacy.privacy_policy.nextme_app_2a")} {API.NAME} {t("Privacy.privacy_policy.nextme_app_2b")}
            </li>{" "}
            <li>
            {t("Privacy.privacy_policy.nextme_app_3")} {API.NAME} {t("Privacy.privacy_policy.nextme_app_3a")} {API.NAME} {t("Privacy.privacy_policy.nextme_app_3b")}
            </li>
            <li>
            {t("Privacy.privacy_policy.nextme_app_4")}
            </li>
            <li>
            {t("Privacy.privacy_policy.nextme_app_5")}
            </li>
          </ol>
          <br />
          {API.NAME} {t("Privacy.privacy_policy.collects_uses_data")}
          <ul>
            <li>{t("Privacy.privacy_policy.collects_uses_data1")}</li>
            <li>
            {t("Privacy.privacy_policy.collects_uses_data2")}
            </li>
            <li>
            {t("Privacy.privacy_policy.collects_uses_data3")}
            </li>
            <li>
            {t("Privacy.privacy_policy.collects_uses_data4")}
            </li>
            <li>
            {t("Privacy.privacy_policy.collects_uses_data5")}
            </li>
            <li>
            {t("Privacy.privacy_policy.collects_uses_data6")}
            </li>
            <li>
            {t("Privacy.privacy_policy.collects_uses_data7")}
            </li>
            <li>{t("Privacy.privacy_policy.collects_uses_data8")}</li>
          </ul>
          <strong>{API.NAME} {t("Privacy.privacy_policy.pickerapp")}</strong>
          <br />
          <p>
          {t("Privacy.privacy_policy.pickerapp_a")} {API.NAME} {t("Privacy.privacy_policy.pickerapp_b")}
          </p>
          <div className="Policy_Text2">{t("Privacy.privacy_policy.picker_app")}</div>
          <p>
          {t("Privacy.privacy_policy.picker_app_a")} {API.NAME}’s {t("Privacy.privacy_policy.picker_app_b")} {API.NAME} {t("Privacy.privacy_policy.picker_app_c")}
          </p>
          <ol>
            <li>
            {t("Privacy.privacy_policy.picker_app_1")}
            </li>
            <li>
            {t("Privacy.privacy_policy.picker_app_2")}
              <br />
              {t("Privacy.privacy_policy.picker_app_2a")} {API.NAME} {t("Privacy.privacy_policy.picker_app_2b")}
            </li>
            <li>
            {t("Privacy.privacy_policy.picker_app_3")} {API.NAME} {t("Privacy.privacy_policy.picker_app_3a")} {API.NAME} {t("Privacy.privacy_policy.picker_app_3b")}
            </li>
            <li>
            {t("Privacy.privacy_policy.picker_app_4")}
            </li>
            <li>
            {t("Privacy.privacy_policy.picker_app_5")}
            </li>
          </ol>
          <br />
          <br />
          {API.NAME} {t("Privacy.privacy_policy.also_use_data")}
          <ul>
            <li>
            {t("Privacy.privacy_policy.also_use_data1")}{" "}
            </li>
            <li>
            {t("Privacy.privacy_policy.also_use_data2")}
            </li>
            <li>
            {t("Privacy.privacy_policy.also_use_data3")}
            </li>
            <li>{t("Privacy.privacy_policy.also_use_data4")}</li>
          </ul>
          <div className="Policy_Text2">{t("Privacy.privacy_policy.data_sharing")}</div>
          {API.NAME} {t("Privacy.privacy_policy.data_sharing_a")}
          <br />
          {t("Privacy.privacy_policy.data_sharing_b")}
          <ul>
            <li>{t("Privacy.privacy_policy.data_sharing_1")}</li>
            <li>
            {t("Privacy.privacy_policy.data_sharing_2")}
            </li>
            <li>
            {t("Privacy.privacy_policy.data_sharing_3")}
            </li>
          </ul>
          <br />
          <div className="Policy_Text2">{t("Privacy.privacy_policy.changes_to_policy")}</div>
          <p>
          {t("Privacy.privacy_policy.changes_to_policy1")}
          </p>
          <br />
        </Col>
      </Container>
    </div>
  );
};

export default Privacy;
