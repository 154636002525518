import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import API from "../../config/API";
import {
  Avatar,
  Button,
  Form,
  Input,
  List,
  Pagination,
  Select,
  notification,
} from "antd";
import { GET, POST } from "../../utils/apiCalls";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { Col, Row } from "react-bootstrap";
import Search from "antd/es/input/Search";
import { MdOutlineDeleteOutline } from "react-icons/md";
import Loading from "../../components/loading";

function OrderSubstitutionScreen() {
  const Role = useSelector((state: any) => state.User?.user?.data?.role);
  const [order, setOrder] = useState<any>({});
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [loading2, setLoading2] = useState(true);
  const [categories, setCategories] = useState<any[]>([]);
  const User = useSelector((state: any) => state.User.user?.data);
  const [form] = Form.useForm();
  const [products, setProducts] = useState<any[]>([]);
  const [meta, setMeta] = useState<any>({});
  const [category, setCategory] = useState<any>("");
  const [store, setStore] = useState<any>({});
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const { Option } = Select;
  const [selected, setSelected] = useState<any>(null);
  const [substitute, setSubstitute] = useState<any[]>([]);
  const navigate=useNavigate()
  const getOrderDetails = async () => {
    const url =
      Role == "admin"
        ? API.ORDER_GETONE_ADMIN + params?.id
        : API.ORDER_GETONE_SELLER + params?.id;
    if (params.id) {
      try {
        const response: any = await GET(url, null);
        if (response?.status) {
          setOrder(response?.data);
        } else {
          Notifications["error"]({
            message: response?.message ?? "",
            description: "",
          });
        }
      } catch (err) {
        Notifications["error"]({
          message: "Something went wrong.",
          description: "",
        });
      } finally {
      }
    }
  };
  const getProducts = async (
    current: number,
    categ: number | string = "",
    search: string = ""
  ) => {
    setLoading(true);
    const url =
      API.PRODUCTS_BYSTORE +
      `?order=DESC&price=ASC&page=${current}&take=${pageSize}&subCategory=${categ}&query=${search}&instock=1`;
    try {
      const response: any = await GET(url, null);
      if (response.status) {
        setProducts(response.data);
        setMeta(response.meta);
      } else {
        Notifications.error({ message: response.message ?? "" });
      }
    } catch (err) {
      Notifications.error({ message: "Something went wrong" });
    } finally {
      setLoading(false);
    }
  };

  const getStoreDetails = async () => {
    const url = API.STORE_SEARCH_GETINFO;
    try {
      const response: any = await GET(url, null);
      if (response.status) {
        setStore(response.data?.store);
        setCategories(response.data?.category);
      } else {
        throw new Error(response.message);
      }
    } catch (err) {
    } finally {
      setLoading2(false);
    }
  };
  const onSearch = async (value: string) => {
    await getProducts(page, category, value);
  };
  const orderItemOnchange = (val: any) => {
    if (!Array.isArray(order?.orderItems)) return;
    const item = order?.orderItems?.find((ite: any) => ite?.id == val);
    if (item) {
      setSelected(item);
      form.setFieldsValue({ substitute: item?.quantity });
    }
  };
  const changePage = async (page: number) => {
    await getProducts(page);
    setPage(page);
  };
  const formSubmitHandler = async (value: any) => {
    if (substitute?.length == 0) {
      Notifications["error"]({
        message: "Products Not selected",
        description: "Select atleast one product for substitution.",
      });
      return;
    }
    const body = {
      orderId: order?.order_id,
      ...value,
      substitute: substitute?.map((item) => item?._id),
    };
    const url = API.ORDER_SUBSTITUTION;
    setLoading2(true);
    try {
      const response: any = await POST(url, body);
      if (response?.status) {
        Notifications["success"]({
          message: "Substitution Request sent successfully.",
        });
        navigate(`/auth/Orders/${order?.order_id}`);
      } else {
        Notifications["error"]({
          message: response?.message,
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Failed to submit.. please try again.",
      });
    } finally {
      setLoading2(false);
    }
  };
  useEffect(() => {
    getOrderDetails();
    getStoreDetails();
    getProducts(1);
  }, []);
  return (
    <div className="p-2">
      {contextHolder}
      <h4>Order Substitution #{order?.order_id ?? ""}</h4>
      <br />
      <div className="row">
        <div className="col-8">
          <Form
            form={form}
            name="basic"
            onFinish={formSubmitHandler}
            layout="vertical"
          >
            <div className="row">
              <div className="col-6">
                {" "}
                <Form.Item
                  rules={[{ required: true, message: "Please Select Product" }]}
                  label="Select Product to Substitute"
                  name={"orderItemId"}
                >
                  <Select
                    className="w-100"
                    size="middle"
                    onChange={orderItemOnchange}
                  >
                    {order?.orderItems?.map((item: any, index: number) => {
                      return (
                        <Option value={item.id} key={index}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  label="Available Quantity"
                  rules={[
                    {
                      required: true,
                      message: "Please Input Available Quantity of Product",
                    },
                    {
                      validator: (_, value) => {
                        if (value == null || value == "") {
                          return Promise.resolve();
                        }
                        if (
                          !isNaN(value) &&
                          value <= (selected?.quantity ?? 1)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            `Number must be less than or equal to ${
                              selected?.quantity ?? 1
                            }`
                          )
                        );
                      },
                    },
                  ]}
                  name={"availableQuantity"}
                >
                  <Input
                    type="number"
                    size="middle"
                    placeholder={`Required Quanatity: ${
                      selected?.quantity ?? ""
                    }`}
                    onChange={(va) => {
                      const value =
                        Number(selected?.quantity) - Number(va?.target?.value);
                      form.setFieldsValue({
                        substitute: value < 0 ? 0 : value,
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  label="Substitute Quantity"
                  dependencies={["availableQuantity"]}
                  name={"substitute"}
                >
                  <Input type="number" size="middle" disabled />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  label="Your Remark"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Remark.",
                    },
                  ]}
                  name={"remark"}
                >
                  <TextArea rows={1} placeholder="Enter remark" />
                </Form.Item>
              </div>
            </div>
          </Form>
          <div className="d-flex justify-content-between">
            <h5>Select Similiar Product</h5>
            <Search
              placeholder="Search Products"
              className="w-50"
              allowClear
              enterButton="Search"
              width={"100px"}
              size="middle"
              onSearch={onSearch}
              //   loading={searching}
            />
          </div>
          <div className="d-flex gap-2 pos-subcategories mt-2 ms-2">
            <div
              style={{ cursor: "pointer", whiteSpace: "nowrap" }}
              className={`pos-tags px-3 align-self-center text-bold ${
                category == "" ? "active" : ""
              }`}
              key={4444}
              onClick={() => {
                getProducts(page, "");
                setCategory("");
              }}
            >
              {"All"}
            </div>
            {categories?.map((item: any, index: number) => (
              <div
                style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                className={`pos-tags px-3 align-self-center text-bold ${
                  item._id == category ? "active" : ""
                }`}
                key={index}
                onClick={() => {
                  getProducts(page, item._id);
                  setCategory(item._id);
                }}
              >
                {item?.name}
              </div>
            ))}
          </div>
          <br />
          {loading ? (
            <Loading />
          ) : products?.length ? (
            <Row className="g-2">
              {products.map((item: any, key: number) => (
                <Col lg="3" md="4" sm="6" key={key}>
                  <div className="product-card-pos border pb-2 h-100">
                    <div className="img-container">
                      <img src={item?.image} alt="" className="img" />
                    </div>
                    <div className="px-2 product-card-pos-title-card">
                      <p className="product-card-pos-title">
                        {item.name ?? ""}
                      </p>{" "}
                      <div className="d-flex justify-content-between">
                        {" "}
                        <p className="price fw-bold">
                          {Settings.currency}
                          {item.retail_rate ?? 0}
                        </p>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => {
                            const exist = substitute.find(
                              (it) => it?._id == item?._id
                            );
                            if (!exist) setSubstitute((pre) => [item, ...pre]);
                          }}
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : null}

          <div className="d-flex justify-content-center mt-3">
            <Pagination
              current={page || 1}
              pageSize={pageSize || 10}
              total={meta?.itemCount || 0}
              defaultCurrent={1}
              responsive={true}
              defaultPageSize={pageSize || 10}
              disabled={false}
              hideOnSinglePage={true}
              onChange={(current: any, size: any) => {
                changePage(current);
              }}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="position-sticky top-0">
            <h5>{`Selected Products (${substitute?.length ?? 0})`}</h5>
            <List
              itemLayout="horizontal"
              dataSource={substitute}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <a
                      key="list-loadmore-edit"
                      onClick={() => {
                        const arrra = [...substitute];
                        const index = arrra.findIndex(
                          (it) => it?._id == item?._id
                        );
                        if (index != -1) {
                          setSubstitute(
                            arrra.filter((it) => item?._id != it?._id)
                          );
                        }
                      }}
                    >
                      <MdOutlineDeleteOutline color="red" />
                    </a>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar src={item?.image} size={40} shape="square" />
                    }
                    title={<a href="https://ant.design">{item.name}</a>}
                    description={item?.description}
                  />
                </List.Item>
              )}
            />
            <div className="col-12 flex-column gap-2 gy-2">
              <Button key="cancel" onClick={() => {}} size="large" block>
                Cancel
              </Button>
              <Button
                key="save"
                type="primary"
                className="mt-2"
                onClick={form.submit}
                loading={loading2}
                size="large"
                block
              >
                Request Substitution
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSubstitutionScreen;
