import React, { useEffect, useState } from "react";
import SettlementOrders from "../components/orderItems";
import API from "../../../config/API";
import { GET } from "../../../utils/apiCalls";
import { notification } from "antd";
import AdminLoading from "../../components/AdminLoading";
import SettlementSummary from "../components/settlements";

function SettlementScreen3() {
  const [Notifications, contextHolder] = notification.useNotification();
  const [settlements, setSettlements] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const getOrderCount = async () => {
    const url = API.SETTLEMENT_DETAILS_SUMMARY;
    try {
      const response: any = await GET(url, null);
      if (response.status) {
        setSettlements(response?.data);
      } else {
        Notifications["error"]({
          message: response?.message,
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "somethig went wrong..",
        description: "",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getOrderCount();
  }, []);
  return (
    <>
      {contextHolder}
      {loading ? <AdminLoading /> : <SettlementSummary order={settlements} />}
    </>
  );
}

export default SettlementScreen3;
