import React, { useEffect, useState, useRef } from "react";
import { Alert, Modal, Spin, notification } from "antd";
import API from "../../../config/API";
import "./style.scss";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { LocationType } from "../../../shared/types/types";
import { useDispatch, useSelector } from "react-redux";
import { storeLocation } from "../../../redux/slices/locationSlice";
import { LoadingOutlined } from "@ant-design/icons";
import AutoCompleteLocationInput from "./AutoCompleteLocationInput";
import { IoLocationOutline } from "react-icons/io5";
import MapPicker from "react-google-map-picker";
import { GET } from "../../../utils/apiCalls";

const ChooseLocationModal = (props: any) => {
  const dispatch = useDispatch();
  const inputRef: any = useRef(null);
  const LocationDetails: LocationType = useSelector(
    (state: any) => state.Location.location
  );
  const [notificationApi, contextHolder2] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const [zoom, setZoom] = useState(10);

  const [defaultLocation, setDefaultLocation] = useState({
    lat: LocationDetails?.latitude || 25.204849,
    lng: LocationDetails?.longitude || 55.270782,
  });

  function handleChangeLocation(lat: any, lng: any) {
    getLocation(lat, lng);
    setDefaultLocation({ lat: lat, lng: lng });
  }

  const getLocation = async (lat: number, long: number) => {
    try {
      const response: any = await GET(
        API.GOOGLE_PLACEPICKER + `?latitude=${lat}&longitude=${long}`,
        null
      );
      if (response?.status) {
        dispatch(storeLocation(response?.data));
        setIsLoading(false);
        props?.modalClose(false);
        notificationApi.success({
          message: `Your location has been set to ${response?.data?.full_address}`,
        });
      } else {
        handleError({ message: "Unable to get your Current location." });
      }
    } catch (err) {}
  };

  function handleLocationClick() {
    try {
      setIsLoading(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          await getLocation(latitude, longitude);
        }, handleError);
      } else {
        handleError({ message: "Location supported" });
      }
    } catch (err) {
      handleError({ message: "Location supported" });
    }
  }

  const handleError = (error: any) => {
    setError(`Unable to get your Location. ${error.message}`);
    setIsLoading(false);
  };

  const focusInput = () => {
    setTimeout(() => {
      if (props?.open == true) {
        inputRef?.current?.focus();
      }
    }, 10);
  };

  useEffect(() => {
    focusInput();
  }, [props?.open]);

  return (
    <Modal
      open={
        !LocationDetails.latitude && !LocationDetails.longitude
          ? true
          : props?.open
      }
      onCancel={() => {
        if (!LocationDetails.latitude && !LocationDetails.longitude) {
          setError(`Please choose Your Location to continue`);
          return;
        }
        props?.modalClose(false);
      }}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
    >
      {contextHolder2}
      <div className="current-location-txt4">
        Select a location for delivery
      </div>
      <div className="current-location-txt5">
        Choose your address location to see product availability and delivery
        options
      </div>
      <br />
      <MapPicker
        zoom={zoom}
        style={{ height: "200px" }}
        onChangeLocation={handleChangeLocation}
        onChangeZoom={(newZoom) => setZoom(newZoom)}
        apiKey={process.env.REACT_APP_GOOGLE_TOKEN || ""}
        defaultLocation={defaultLocation}
      />
      <br />
      <div className="current-location-box" onClick={handleLocationClick}>
        <div>
          {isLoading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            <FaLocationCrosshairs size={24} />
          )}
        </div>
        <div className="current-location-box2">
          <div className="current-location-txt2">Use My Current Location</div>
        </div>
      </div>

      <br />
      <AutoCompleteLocationInput
        ref={inputRef}
        size="large"
        onCancel={() => {
          setError(null);
          props?.modalClose(false);
        }}
      />
      {LocationDetails.latitude ? (
        <>
          <div>Current Location</div>
          <div className="current-location-box">
            <div>
              <IoLocationOutline size={30} style={{ marginRight: 10 }} />
            </div>
            <div>
              <div className="current-location-txt1">
                {LocationDetails?.country}
              </div>
              <div className="current-location-txt3">
                {LocationDetails.latitude
                  ? LocationDetails.full_address
                    ? LocationDetails?.full_address
                    : LocationDetails.postal_code
                  : null}
              </div>
            </div>
          </div>
        </>
      ) : null}
      {error ? (
        <Alert
          style={{ marginTop: 20 }}
          type="error"
          message={<div style={{ color: "red", fontSize: 16 }}>{error}</div>}
        />
      ) : null}
    </Modal>
  );
};

export default ChooseLocationModal;
