import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input, notification } from "antd";
import { BiErrorCircle } from "react-icons/bi";
import { InputOTP } from "antd-input-otp";

import { Auth } from "../../config/firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";

import API from "../../config/API";
import { POST } from "../../utils/apiCalls";
import { login } from "../../redux/slices/userSlice";
import PrefixSelector from "../../components/prefixSelector";

function PhoneLogin() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [verification, setverification] = useState(false);
  const [autho, setautho] = useState<any>(null);
  const [data, setdata] = useState<any>({});
  const [error, setError] = useState<any>(null);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const checkuser = async () => {
    try {
      let user: any = Auth.currentUser;
      if (user?.phoneNumber) {
        signOut(user);
      }
    } catch (err) {
      console.log("checkuser err", err);
    }
  };

  const LoginPhone = async (values: any) => {
    try {
      setdata(values);
      setIsLoading(true);
      checkuser();
      setError("");
      let recaptchas = await new RecaptchaVerifier(Auth, "recaptcha", {});
      let phone = `${values.code}${values.phone}`;
      let checkPhone: any = await signInWithPhoneNumber(
        Auth,
        phone,
        recaptchas
      );
      if (checkPhone?.verificationId) {
        setautho(checkPhone);
        setverification(true);
      } else {
        setError("Phone Login Failed.. Try later");
      }
      setIsLoading(false);
    } catch (err: any) {
      console.log(err);
      if (err?.message?.includes("invalid-phone")) {
        setError("Invalid Phone number Try again");
      } else {
        setError("Phone Login Failed.. Try later");
      }
      setverification(false);
      setIsLoading(false);
    }
  };

  const verifyOtp = async (values: any) => {
    try {
      setIsLoading(true);
      let otp = values.otp.join("");
      let verify = await autho.confirm(otp);
      const token = await verify?.user?.getIdToken();
      if (token) {
        await PhoneLogin(token);
      } else throw new Error("No token found.");
    } catch (err: any) {
      if (err?.message?.includes("invalid-verification")) {
        setError("Invalid Otp, Please try again");
      } else {
        setError("Phone Login failed. Please try later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const PhoneLogin = async (token: string) => {
    try {
      setIsLoading(true);
      let url = API.LOGIN_PHONE;
      let body = {
        code: data?.code,
        idToken: token,
      };
      var loginRes: any = await POST(url, body);
      if (loginRes.status) {
        notificationApi.success({ message: "Login Successful" });
        dispatch(login(loginRes));
      } else {
        setError(loginRes.message);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="PhoneLogin">
      {contextHolder}
      <div>
        <div className="LoginScreen-txt2">
          {verification
            ? `Enter OTP send to your mobile number ${data?.code} ${data?.phone}`
            : "Enter your phone Number and we’ll check for you."}
        </div>
        <br />
        <Form
          onFinish={verification ? verifyOtp : LoginPhone}
          initialValues={{ code: "+971" }}
          form={form}
        >
          {verification ? (
            <Form.Item
              name="otp"
              rules={[
                {
                  required: true,
                  message: "Input 6 digit verification code !",
                },
              ]}
            >
              <InputOTP autoFocus inputType="numeric" length={6} />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Input your phone number!",
                  },
                  { min: 8, message: "Please enter a valid phone number" },
                  { max: 14, message: "Please enter a valid phone number" },
                ]}
              >
                <Input
                  addonBefore={<PrefixSelector />}
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Enter Phone Number"
                  type="number"
                />
              </Form.Item>
              <div id="recaptcha"></div>
            </>
          )}
          {error ? (
            <div className="LoginScreen-errortxt">
              <BiErrorCircle />
              &nbsp;
              {error}
            </div>
          ) : null}
          <Form.Item>
            <Button
              loading={isLoading}
              block
              size="large"
              type="primary"
              htmlType="submit"
              style={{ height: 45 }}
            >
              {verification ? "Login" : "Get OTP"}
            </Button>
            {error ? (
              <Button
                className="mt-2"
                block
                size="large"
                style={{ height: 40 }}
                htmlType="submit"
                onClick={(values: any) => {
                  LoginPhone(values);
                  form.setFieldsValue({ otp: "" });
                }}
              >
                {"Resend OTP"}
              </Button>
            ) : null}
          </Form.Item>
        </Form>
        <hr />
      </div>
    </div>
  );
}
export default PhoneLogin;
